import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const CovidBar = ({ showBar }) => (
  showBar ? (
    <div className="covidBar">
      <div className="container">
        <span className="emoji" role="img" aria-label="Hi!">👋</span> Learn about <Link to="/covid">Mortgages and COVID</Link>
      </div>
    </div>
  ) : null
);

CovidBar.propTypes = {
  showBar: PropTypes.bool.isRequired
};


export default CovidBar;
