import React from 'react';
import santander from '../images/santander.svg';
import rbs from '../images/rbs.svg';
import lloyds from '../images/lloyds-bank.svg';
import barclays from '../images/barclays.svg';
import firstdirect from '../images/first-direct.svg';
import hsbc from '../images/hsbc.svg';
import natwest from '../images/natwest.svg';
import halifax from '../images/halifax.svg';

const Lenders = () => (
  <article className="lenders">
    <div className="container">
      <h1 className="title-tertiary">Search lenders to stay on the best mortgage rates</h1>
      <div className="lenders-content">
        <img src={santander} alt="santander" />
        <img src={rbs} alt="rbs" />
        <img src={lloyds} alt="lloyds" />
        <img src={barclays} alt="barclays" />
        <img src={firstdirect} alt="firstdirect" />
        <img src={hsbc} alt="hsbc" />
        <img src={natwest} alt="natwest" />
        <img src={halifax} alt="halifax" />
      </div>
    </div>
  </article>
);

export default Lenders;
