import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/anji-light.svg';

const Footer = () => (
  <article className="footer">
    <div className="container footer-content">
      <section className="row row-gutterSmall footer-links">
        <div className="footer-links-col footer-links-logo col col-desktop4 col-tablet4 col-mobile12">
          <img src={logo} alt="anji" />
        </div>
        <ul className="footer-links-col col col-desktop4 col-tablet4 col-mobile12">
          <li><h1 className="footer-links-title">Company</h1></li>
          <li><Link to="/about">About us</Link></li>
        </ul>
        <ul className="footer-links-col col col-desktop4 col-tablet4 col-mobile12">
          <li><h1 className="footer-links-title">Contact</h1></li>
          <li>Email: <a href="mailto:hello@anjihomes.com">hello@anjihomes.com</a></li>
        </ul>
      </section>
      <section className="footer-disclaimer">
        <div className="row-separationSmall">
          <p className="row-separationSmall">Anji is a trading style of Seven Summits Financial Limited, registered in England and Wales with company number 10848518. Registered office: 14 Gray’s Inn Road, London, WC1X 8HN.</p>
          <p className="row-separationSmall">This is an information only service and listings are provided by Anji on a non-advised basis. This means that no advice is given or implied and you are solely responsible for deciding whether the product is suitable for your needs. If you are not sure which is the right product for you, you should seek advice. Anji is only providing a comparison service and is not a lender. The table shows a range of products from the market and this service does not consider your personal credit position.</p>
          <p className="row-separationSmall">Anji will not charge you a fee for using this service, but may receive a payment from the companies we refer you to.</p>
          <p className="row-separationSmall">We suggest you read the <Link to="/legal/terms-and-conditions">Terms and Conditions</Link>, <Link to="/legal/terms-of-use">Terms of Use</Link> and <Link to="/legal/privacy-policy">Privacy Policy</Link>.</p>
        </div>
      </section>
    </div>
  </article>
);

export default Footer;
