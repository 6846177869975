import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormContext } from '../MultiStepForm';
import Input from '../../decorators/withInput';
import {
  inputsTypes, inputsDefaultTypes
} from '../../types';

const Step3 = ({ handleChangeInput, inputs }) => {
  const { setErrorForm } = useContext(FormContext);

  useEffect(() => {
    setErrorForm(false);
  }, []);

  useEffect(() => {
    if (inputs.equityPercentage === undefined) {
      setErrorForm(true);
    } else {
      setErrorForm(false);
    }
  }, [inputs.equityPercentage]);

  return (
    <article className="steps">
      <h1 className="steps-title">What percentage amount of Help to Buy: Equity Loan did you take out?</h1>
      <p className="steps-subtitle">This is up to 20% or 40% if in London</p>
      <div className="steps-form row row-gutterTiny">
        <div className="col col-desktop3 col-mobile4">
          <Input
            maxValue={40}
            minValue={0}
            name="equityPercentage"
            type="tel"
            format="percentage"
            required
            onChange={handleChangeInput}
            value={inputs.equityPercentage}
          />
        </div>
      </div>
    </article>
  );
};

Step3.propTypes = {
  inputs: inputsTypes,
  handleChangeInput: PropTypes.func
};


Step3.defaultProps = {
  inputs: inputsDefaultTypes,
  handleChangeInput: () => {},
};

const mapStateToProps = (state) => ({
  inputs: state.inputs,
});

export default connect(
  mapStateToProps
)(Step3);
