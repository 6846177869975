import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import initialState from '../initialState';
import Error404 from './pages/Error';

import {
  inputsTypes, inputsDefaultTypes
} from '../types';

const BridgeInputs = ({
  inputs, children
}) => {
  const checkDirectAccess = () => (
    initialState.purchaseDateMonth === inputs.purchaseDateMonth
      || initialState.purchaseDateYear === inputs.purchaseDateYear
      || initialState.purchasePrice === inputs.purchasePrice
      || initialState.equityPercentage === inputs.equityPercentage
      || initialState.currentValue === inputs.currentValue
      || initialState.currentLender === inputs.currentLender
      || initialState.outstandingBalance === inputs.outstandingBalance
      || initialState.initialRateEndDateMonth === inputs.initialRateEndDateMonth
      || initialState.initialRateEndDateYear === inputs.initialRateEndDateYear
      || initialState.currentRate === inputs.currentRate
  );

  return (
    !checkDirectAccess() ? children : <Error404 />
  );
};

BridgeInputs.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  inputs: inputsTypes,
};

BridgeInputs.defaultProps = {
  children: null,
  inputs: inputsDefaultTypes,
};

const mapStateToProps = (state) => ({
  inputs: state.inputs,
});

export default connect(mapStateToProps)(BridgeInputs);
