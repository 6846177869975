import React from 'react';
/* eslint-disable */

const TermsOfUse = () => (
  <>
    <h1 className="legal-title">Terms of Use</h1>
    <ol className="legal-index">
      <li><a href="#introduction">Introduction</a></li>
      <li><a href="#registration">Registration</a></li>
      <li><a href="#accessing">Accessing our site</a></li>
      <li><a href="#our-content">Our content </a></li>
      <li><a href="#indemnification">Indemnification</a></li>
      <li><a href="#disclaimer">Disclaimer of warranties and liability</a></li>
      <li><a href="#our-services">Linking to our services</a></li>
      <li><a href="#general-information">General information</a></li>
    </ol>
    <ol className="legal-list">
      <li id="introduction">
        <h3 className="title-xxl legal-list-title">Using our service</h3>
        <p>By using our service, you are agreeing with Seven Summits Financial Limited (“Anji”) to be bound by these Terms of Use in relation to this website and those services which are provided by Anji.</p>
        <p>We may change or update these terms at any time without prior notice. Your continued use of this website and these services means that you accept any new or modified terms.</p>
      </li>
      <li id="registration">
        <h3 className="title-xxl legal-list-title">Registration</h3>
        <p>By registering, you certify that all information you provide in the registration is accurate. Customers may only maintain one active registration. In other words, it's one registration per person. By accepting this agreement, you certify that you have no other registration with this website.</p>
        <p>You must have a valid email registered with us at all times. If an email that we send to you should bounce for some reason, your account may be temporarily suspended until you contact us with a verifiable address.</p>
      </li>
      <li id="accessing">
        <h3 className="title-xxl legal-list-title">Accessing our site</h3>
        <p>Access to our site and services is permitted on a temporary basis, and we reserve the right to withdraw or amend the service we provide without notice. We will not be liable if for any reason our site or services are unavailable at any time or for any period.</p>
        <p>From time to time, we may restrict access to some parts, or all of our sites or services, to users who have registered with us.</p>
      </li>
      <li id="our-content">
        <h3 className="title-xxl legal-list-title">Our content</h3>
        <p>In this section, ‘Content’ means any information, mode of expression, or other material and services found on this website. This includes question, answer, review, comment, tip or other piece of user-generated content, as well as software, our writings, graphics and any and all other features that may be found on the site.</p>
        <p>Except as expressly provided in these Terms of Use, all of the Content on or in this site or services is the property of Anji or its licensors and is protected by UK and international copyright laws.</p>
        <p>Our Content is intended for individual, personal use only. Accordingly, you may make one copy of our content for your personal, non-commercial use, provided that any material copied remains intact and includes the following notice: “©2019 Seven Summits Financial Limited.” Any other copying, distribution, storing or transmission of any kind, or any sort of commercial use of our content, is strictly prohibited without our prior written permission.</p>
        <p>The information provided on the site is for general information purposes only. It is not, and should not be construed as, financial or other professional advice.</p>
        <p>In the material we make available on our site, we rely on a wide variety of sources and other websites. We believe these sources of data to be generally accurate and reliable, but sometimes they may not be. Anji makes no claims or representations as to the accuracy, completeness, or truth of any material contained on our site. Nor is Anji liable for any errors or delays in the content or transmission of the data on our site.</p>
      </li>
      <li id="indemnification">
        <h3 className="title-xxl legal-list-title">Indemnification</h3>
        <p>You agree to indemnify, defend, and hold harmless Anji, affiliates, directors, officers and employees from and against any claim, liability, cost, damage or loss we may incur (including, without limitation, solicitors’ fees) as a result of any violation by you of your obligations under these Terms of Use.</p>
      </li>
      <li id="disclaimer">
        <h3 className="title-xxl legal-list-title">Disclaimer of warranties and liability</h3>
        <p>Anji cannot and does not warrant the completeness or accuracy of the news, information, or other content found on our site, or its usefulness for any particular purpose.</p>
        <p>You understand and acknowledge that information posted by third party providers are the sole responsibility of the person from which such material originated. Anji takes no responsibility for the accuracy and completeness of the information posted  our comparison site.</p>
        <p>You agree that Anji shall not be liable for any decision made or action taken by you or others based upon reliance on news, information, or any material published by Anji.</p>
        <p>All information provided on Anji is to be used on an 'as is, with all faults' basis. We make no promises that our content or the service itself will be delivered to you uninterrupted, secure, error-free or delivered in a timely manner. It means that you agree that under no circumstances shall Anji be liable for any direct, indirect, incidental or any other type of loss or injury resulting from your use, or downloading of any content on our site . This includes, but is not limited to, loss or injury caused by contingencies beyond our control in creating or delivering any portion of Anji. Nothing herein excludes or limits Anji’s liability for death or personal injury caused by its proven negligence, or excludes or limits our duties or any liability under any applicable law.</p>
        <p>We will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of our site or services, or to your downloading of any material posted on it, or on any website linked to it.</p>
      </li>
      <li id="our-services">
        <h3 className="title-xxl legal-list-title">Linking to our services</h3>
        <p>You may link to our pages or services, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.</p>
        <p>You must not establish a link from any website that is not owned by you.</p>
        <p>Our site and services must not be framed on any other site, nor may you create a link to any part of our site or services other than the home page. We reserve the right to withdraw linking permission without notice.</p>
      </li>
      <li id="general-information">
        <h3 className="title-xxl legal-list-title">General information</h3>
        <p>If any provision in this Agreement is invalid or unenforceable, the remaining provisions will continue in full force and effect. These Terms of Use shall be governed by the laws of England and Wales as if the Agreement was a contract wholly entered into and wholly performed within England. The courts of England and Wales shall have exclusive jurisdiction in relation to any disputes arising under or in connection with these Terms of Use, but without prejudice to Anji's ability to bring proceedings against a user in the courts of any jurisdiction.</p>
      </li>
    </ol>
  </>
);

export default TermsOfUse;
