import React from 'react';
import { Helmet } from 'react-helmet';
import Collapse, { Panel } from 'rc-collapse';
import { Link } from 'react-router-dom';
import Layout from '../Layout';
import covidbg from '../../images/covid-bg.jpg';

const Covid = () => (
  <Layout>
    <Helmet>
      <title>Coronavirus and Help to Buy</title>
      <meta name="description" content="The latest information on how coronavirus might affect your Help to Buy remortgage" />
    </Helmet>
    <article className="post">
      <img src={covidbg} alt="covid" />
      <div className="container post-wrapper">
        <h1 className="title-main">Coronavirus and Help to Buy mortgages</h1>
        <div className="post-content">
          <p className="post-title">Overview</p>
          <p>Due to the COVID-19 pandemic, there could be fewer mortgages available to you than usual. Many lenders have tightened their criteria, making it harder for borrowers with lower equity or deposits to get a new deal. Many lenders have lowered the maximum loan to value ratio (LTV) they will offer - some will currently only lend up to a maximum LTV of 60%, so you would need a 40% deposit.</p>
          <p>We update the mortgage deals we compare regularly however there have been many changes and we expect this to continue over the next weeks and months. This may lead to deals being withdrawn or changed after you are shown it.</p>
        </div>
        <p className="post-title">Frequently asked questions</p>
        <div className="row row-gutterEnormous row-separationBig">
          <div className="col col-desktop12 col-mobile12">
            <Collapse className="listGeneric" accordion>
              <Panel header="Can I get a mortgage payment holiday?">
                <p>The government has announced that you could apply for a 3 month mortgage payment holiday if you can’t pay your mortgage because of COVID-19.</p>
                <p>A mortgage payment holiday is a break from paying your mortgage which has been agreed by your lender. You should get in touch with your lender as soon as possible. To be eligible for a payment holiday you will need to be up to date with your mortgage. You won&apos;t need to prove that COVID-19 has affected your finances - they will trust that you give them accurate information.</p>
                <p>Taking a mortgage payment holiday because of coronavirus shouldn&apos;t affect your credit score if you&apos;ve got your lender&apos;s permission. But you can ask your lender to confirm.</p>
              </Panel>
              <Panel header="Can I get a payment holiday on my Help to Buy: Equity Loan?">
                <p>If you are past the 5 year interest free period on your Equity Loan and you are struggling to manage the payments, you should get in touch with <a href="https://www.myfirsthome.org.uk/" target="_blank" rel="noopener noreferrer">Target</a> as soon as possible.</p>
                <p>Depending on your circumstances they should be able to offer you a payment holiday of up to 3 months i.e. in line with the holidays being provided on normal mortgages.</p>
              </Panel>
              <Panel header="Can I still remortgage?">
                <p>It is still possible to remortgage however many lenders have tightened their criteria, making it harder for borrowers with lower equity to get a new deal. Many lenders have lowered the maximum loan to value ratio (LTV) they will offer - some will currently only lend up to a maximum LTV of 60%, so you would need a 40% deposit.</p>
                <p>However if you can find a remortgage deal, it is likely to be beneficial for you to do so rather than going onto your lender’s SVR. Use our <Link to="/search">calculator</Link> to compare what options might be available for you. We update the mortgage deals we compare regularly however there have been many changes and we expect this to continue over the next weeks and months. This may lead to deals being withdrawn or changed after you are shown it.</p>
              </Panel>
              <Panel header="Can I redeem my Help to Buy: Equity Loan?">
                <p>As a temporary measure in response to COVID-19, from April until it is possible to get normal RICS valuations, as set out in the redemption guidance, it is possible to use desktop valuations instead of physical valuations to redeem the Equity Loan.</p>
                <p>A desktop valuation is carried out by a surveyor registered with the independent Royal Institution of Chartered Surveyors (RICS). The surveyor does not need to visit your home. There are some requirements that must be met, including:</p>
                <p>- The surveyor must be approved by the Royal Institution of Chartered Surveyors (RICS). This can be found on the <a href="https://www.rics.org/uk/find-a-member" target="_blank" rel="noopener noreferrer">RICS database</a></p>
                <p>- The surveyor must be independent of the estate agent undertaking the sale of the property (if applicable)</p>
                <p>- The surveyor must not be related to or known to you, to avoid any conflict of interest.</p>
                <p>For more requirements and other information visit the <a href="https://www.myfirsthome.org.uk/" target="_blank" rel="noopener noreferrer">Target page</a>.</p>
              </Panel>
              <Panel header="Do I have to make the missed payments if I use a holiday?">
                <p>Yes a payment holiday does not write off the interest, rather interest continues to accrue but you are not required to pay immediately. Overall this is likely to mean that you end up paying more on your mortgage in total. Use <a href="https://mortgageholiday.co.uk/" target="_blank" rel="noopener noreferrer">this calculator</a> to estimate the additional cost.</p>
                <p>There are usually three ways that you can catch up on the missed monthly payments at the end of the holiday:</p>
                <p>- adding the deferred instalment(s) to your outstanding mortgage balance (capitalising the amount), so you can pay it over the remaining term of your mortgage,</p>
                <p>- agreeing to a short-term payment arrangement to clear the deferred instalment(s) over several months; or,</p>
                <p>- extending the original term of your mortgage.</p>
              </Panel>
              <Panel header="I’ve been put on furlough, can I still apply for a mortgage?">
                <p>Each lender will have different rules for how they treat mortgage applications from people on furlough. Right now, most lenders are still figuring out what those rules should be.</p>
                <p>So far, NatWest and Nationwide have both confirmed that you can still apply for a mortgage with them if you&apos;ve been furloughed. They&apos;ll use the income you&apos;re getting on furlough to calculate how much you can borrow – rather than your usual income. So if your income has gone down, the amount you can borrow over this period might go down too.</p>
              </Panel>
            </Collapse>
          </div>
        </div>
      </div>
    </article>
  </Layout>
);

export default Covid;
