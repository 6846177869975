import React from 'react';

const Bullets = () => (
  <article className="bullets">
    <div className="container">
      <div className="row row-gutterSmall">
        <div className="bullets-item bullets-wallet col col-desktop4 col-mobile12">
          <h3 className="bullets-title">Better rates</h3>
          <div>Going onto your lender’s standard variable rate could mean overpaying by thousands of pounds - make sure you’re on the best rate available.</div>
        </div>
        <div className="bullets-item bullets-sofa col col-desktop4 col-mobile12">
          <h3 className="bullets-title">Easy process</h3>
          <div>You want to make the right decision. We’ll introduce you to expert advisors who can guide you through the process.</div>
        </div>
        <div className="bullets-item bullets-handshake col col-desktop4 col-mobile12">
          <h3 className="bullets-title">Know your options</h3>
          <div>Use our guides and calculators to understand how you can get the best from your Help to Buy equity loan.</div>
        </div>
      </div>
    </div>
  </article>
);

export default Bullets;
