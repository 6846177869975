import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { createBrowserHistory } from 'history';
import Home from './components/pages/Home';
import Error404 from './components/pages/Error';
import HelpToBuy from './components/pages/HelpToBuy';
import RemortgageOptions from './components/pages/RemortgageOptions';
import Covid from './components/pages/Covid';
import About from './components/pages/About';
import BridgeInputs from './components/BridgeInputs';
import Legal from './components/pages/Legal';
import remortgageToPayOffHelpToBuy from './components/pages/remortgageToPayOffHelpToBuy';
import valuationRepayHelpToBuy from './components/pages/valuationRepayHelpToBuy';
import whenIsHelpToBuyEnding from './components/pages/whenIsHelpToBuyEnding';
import whoAreTargetHca from './components/pages/whoAreTargetHca';
import ScrollToTop from './ScrollToTop';

const App = () => {
  const history = createBrowserHistory();
  history.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });

  return (
    <>
      <Helmet>
        <title>TBD</title>
        <meta name="description" content="TBD" />
      </Helmet>
      <Router history={history}>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/legal/:slug" component={Legal} />
          <Route exact path="/search" component={HelpToBuy} />
          <Route exact path="/search/:slug" component={HelpToBuy} />
          <Route exact path="/result" component={() => <BridgeInputs><RemortgageOptions /></BridgeInputs>} />
          <Route exact path="/covid" component={Covid} />
          <Route exact path="/about" component={About} />
          <Route exact path="/remortgage-to-pay-off-help-to-buy" component={remortgageToPayOffHelpToBuy} />
          <Route exact path="/valuation-to-repay-help-to-buy" component={valuationRepayHelpToBuy} />
          <Route exact path="/when-is-help-to-buy-ending" component={whenIsHelpToBuyEnding} />
          <Route exact path="/who-are-target-hca" component={whoAreTargetHca} />
          <Route component={Error404} />
        </Switch>
      </Router>
    </>
  );
};

export default App;
