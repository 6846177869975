import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormContext } from '../MultiStepForm';
import Input from '../../decorators/withInput';
import {
  inputsTypes, inputsDefaultTypes
} from '../../types';

const Step2 = ({ handleChangeInputCurrency, inputs }) => {
  const { setErrorForm } = useContext(FormContext);

  useEffect(() => {
    setErrorForm(false);
  }, []);

  useEffect(() => {
    if (inputs.purchasePrice === undefined) {
      setErrorForm(true);
    } else {
      setErrorForm(false);
    }
  }, [inputs.purchasePrice , setErrorForm ]);

  return (
    <article className="steps">
      <h1 className="steps-title">What was the purchase price?</h1>
      <p className="steps-subtitle">The full purchase price including the Help to Buy: Equity Loan</p>
      <div className="steps-form">
        <Input
          maxValue={600000}
          type="tel"
          format="currency"
          required
          name="purchasePrice"
          onChange={handleChangeInputCurrency}
          value={inputs.purchasePrice}
        />
      </div>
    </article>
  );
};

Step2.propTypes = {
  inputs: inputsTypes,
  handleChangeInputCurrency: PropTypes.func
};


Step2.defaultProps = {
  inputs: inputsDefaultTypes,
  handleChangeInputCurrency: () => {},
};

const mapStateToProps = (state) => ({
  inputs: state.inputs,
});

export default connect(
  mapStateToProps
)(Step2);
