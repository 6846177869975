import React from 'react';
import { Link } from 'react-router-dom';
import error404 from '../images/404.svg';

const Error404 = () => (
  <article className="error404 layout-content">
    <div className="container">
      <div className="row row-gutterSmall error404-content">
        <div className="col col-desktop5 col-tablet6 col-mobile12">
          <h1 className="title-main">Oops!</h1>
          <h3 className="error404-title row-separationSmall">We can&apos;t seem to find the page you&apos;re looking for.</h3>
          <p className="error404-subtitle">Error code: 404</p>
          <div className="subtitle-main">
            <p>Here are some helpful links instead:</p>
          </div>
          <ul className="error404-links">
            <li><Link to="/">Go to the Home page</Link></li>
          </ul>
        </div>
        <div className="error404-image col col-mobile12">
          <img src={error404} alt="404" />
        </div>
      </div>
    </div>
  </article>
);

export default Error404;
