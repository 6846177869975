import React, { useContext } from 'react';
import { FormContext } from '../components/MultiStepForm';

import { intToCurrency, stringToInt } from '../lib/formats';

const withInput = () => (props) => {
  const {
    minValue, maxValue, handleError, required, format, name, ...oldProps
  } = props;

  const { setError } = useContext(FormContext);
  const renderFormat = () => {
    let baseClassName = 'form-input';
    switch (format) {
      case 'currency':
        baseClassName += ' form-input-currency';
        break;
      case 'percentage':
        baseClassName += ' form-input-percentage';
        break;
      default:
        break;
    }
    return baseClassName;
  };

  const renderValue = (value) => {
    switch (format) {
      case 'currency':
        return intToCurrency(value);
      case 'percentage':
        return value;
      case 'number':
        return stringToInt(value);
      default:
        return value;
    }
  };

  const handleChange = (e) => {
    oldProps.onChange(e.target.value, props.name);
  };


  if ((oldProps.value !== undefined) && (
    typeof (oldProps.value) !== 'number' || oldProps.value > maxValue || oldProps.value < minValue)) {
    setError(true);
  } else {
    setError(false);
  }

  return (
    <div className="form-field">
      <div className={renderFormat()}>
        <input
          {...oldProps}
          value={renderValue(oldProps.value)}
          onChange={handleChange}
        />
      </div>
      <div className="form-field-helper">
        {oldProps.value > maxValue && `The max. is ${maxValue}`}
        {oldProps.value < minValue && `The min. is ${minValue}`}
      </div>
    </div>
  );
};

export default withInput();
