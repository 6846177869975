import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import Layout from '../Layout';
import Tabs from '../Tabs';
import TermsAndConditions from '../TermsAndConditions';
import TermsOfUse from '../TermsOfUse';
import PrivacyPolicy from '../PrivacyPolicy';


const Legal = () => {
  const { slug } = useParams();

  let tab = 0;

  switch (slug) {
    case 'terms-and-conditions':
    default:
      tab = 0;
      break;
    case 'terms-of-use':
      tab = 1;
      break;
    case 'privacy-policy':
      tab = 2;
      break;
  }

  return (
    <Layout>
      <Helmet>
        <title>Anji - terms and conditions</title>
        <meta name="description" content="Anji's terms and conditions, terms of use and privacy policy." />
      </Helmet>
      <article className="legal">
        <div className="container layout-content">
          <Tabs
            currentTab={tab}
          >
            <div title="Terms and conditions">
              <TermsAndConditions />
            </div>
            <div title="Terms of use">
              <TermsOfUse />
            </div>
            <div title="Privacy Policy">
              <PrivacyPolicy />
            </div>
          </Tabs>
        </div>
      </article>
    </Layout>
  );
};

Legal.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

Legal.defaultProps = {
  location: {
    search: null
  },
};

export default Legal;
