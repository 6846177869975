export const monthDiff = (d1, d2) => {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
};

export const eoMonth = (date, months = 0) => new Date(date.getFullYear(), date.getMonth() + months + 1, 0);

export const nextEoMonth = (date) => eoMonth(date, 1);

export const monthsArray = [
  {
    label: 'Select month',
    value: '',
  },
  {
    value: 1,
    label: 'January',
  },
  {
    value: 2,
    label: 'February',
  },
  {
    value: 3,
    label: 'March',
  },
  {
    value: 4,
    label: 'April',
  },
  {
    value: 5,
    label: 'May',
  },
  {
    value: 6,
    label: 'June',
  },
  {
    value: 7,
    label: 'July',
  },
  {
    value: 8,
    label: 'August',
  },
  {
    value: 9,
    label: 'September',
  },
  {
    value: 10,
    label: 'October',
  },
  {
    value: 11,
    label: 'November',
  },
  {
    value: 12,
    label: 'December',
  }
];

export const validateDate = (date, minDate, maxDate) => {
  if (date.month && date.year) {
    if (minDate) {
      if (parseInt(date.year, 10) < minDate.year) {
        return false;
      }
      if (parseInt(date.year, 10) === minDate.year && date.month < minDate.month) {
        return false;
      }
    }
    if (maxDate) {
      if (parseInt(date.year, 10) > maxDate.year) {
        return false;
      }
      if (parseInt(date.year, 10) === maxDate.year && date.month > maxDate.month) {
        return false;
      }
    }
    return true;
  }
  return false;
};
