import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import logo from '../images/anji-dark.svg';
import CovidBar from './CovidBar';

const Navbar = ({ showCovid }) => (
  <nav data-navbar className="navbar">
    <CovidBar showBar={showCovid} />
    <div className="navbar-content container">
      <Link className="no-underline navbar-logo" to="/">
        <img className="navbar-logo-full" src={logo} alt="Anji" />
      </Link>
    </div>
  </nav>
);

Navbar.propTypes = {
  showCovid: PropTypes.bool.isRequired
};

export default Navbar;
