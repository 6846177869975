import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import MultiStepForm from '../MultiStepForm';
import { changeInput, changeSelect } from '../../actions/inputs';
import {
  Step1, Step2, Step3, Step4, Step5, Step6, Step7, Step8
} from '../steps';
import { currencyToInt, stringToInt, stringToFloat } from '../../lib/formats';

const HelpToBuy = ({ actions }) => {
  const handleChangeInput = (value, name) => {
    actions.changeInput(value, name);
  };

  const handleChangeInputRate = (value, name) => {
    actions.changeInput(stringToFloat(value), name);
  };

  const handleChangeInputNumber = (value, name) => {
    actions.changeInput(stringToInt(value), name);
  };

  const handleChangeInputCurrency = (value, name) => {
    handleChangeInput(currencyToInt(value), name);
  };

  const handleChangeSelect = (option, name) => {
    actions.changeSelect(option.value, name);
  };

  return (
    <div className="helpToBuy">
      <Helmet>
        <title>Anji - remortgage search</title>
        <meta name="description" content="Anji - remortgage search" />
        <meta name="robots" content="noindex, follow" />
      </Helmet>
      <MultiStepForm url="../result">
        <Step1
          handleChangeSelect={handleChangeSelect}
        />
        <Step2
          handleChangeInputCurrency={handleChangeInputCurrency}
        />
        <Step3
          handleChangeInput={handleChangeInputRate}
        />
        <Step4
          handleChangeInputCurrency={handleChangeInputCurrency}
        />
        <Step5
          handleChangeSelect={handleChangeSelect}
        />
        <Step6
          handleChangeInputCurrency={handleChangeInputCurrency}
        />
        <Step7
          handleChangeSelect={handleChangeSelect}
          handleChangeInput={handleChangeInputNumber}
        />
        <Step8
          handleChangeInput={handleChangeInputRate}
        />
      </MultiStepForm>
    </div>
  );
};

HelpToBuy.propTypes = {
  actions: PropTypes.shape({
    changeInput: PropTypes.func,
    changeSelect: PropTypes.func,
  }),
};

HelpToBuy.defaultProps = {
  actions: {
    changeInput: () => {},
    changeSelect: () => {},
  },
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    changeInput,
    changeSelect
  },
  dispatch)
});

export default connect(
  null, mapDispatchToProps
)(HelpToBuy);
