import React from 'react';
import PropTypes from 'prop-types';
import Navbar from './Navbar';
import Footer from './Footer';

const Layout = ({ children, showCovid }) => (
  <div className="layout">
    <Navbar showCovid={showCovid} />
    {children}
    <Footer />
  </div>
);

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  showCovid: PropTypes.bool
};

Layout.defaultProps = {
  children: null,
  showCovid: false
};

export default Layout;
