import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Collapse, { Panel } from 'rc-collapse';
import Slider from 'react-rangeslider';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Breadcrumbs from '../Breadcrumbs';
import { changeInput } from '../../actions/inputs';
import { intToCurrency, formatPercentage } from '../../lib/formats';
import getFilteredProducts from '../../lib/getFilteredProducts';
import Card from '../Card';
import habito from '../../images/habito-logo.svg';

import {
  lenderSVRTypes, inputsTypes, inputsDefaultTypes, productsTypes, productsDefaultTypes
} from '../../types';

const RemortgageOptions = ({
  inputs, products, lenderSVR, actions
}) => {
  const handleChangeInput = (value, name) => {
    actions.changeInput(value, name);
  };

  const [assumedHousePriceRate, setAssumedHousePriceRate] = useState(10);
  const initialAssumedHousePrice = inputs.currentValue;

  useEffect(() => {
    handleChangeInput(Math.ceil((initialAssumedHousePrice + (initialAssumedHousePrice * assumedHousePriceRate / 100)) / 1000) * 1000, 'assumedHousePrice');
  }, []);

  const handleChangeAssumedHousePrice = (value) => {
    const assumedHousePrice = Math.ceil((initialAssumedHousePrice + (initialAssumedHousePrice * value / 100)) / 1000) * 1000;
    setAssumedHousePriceRate(value);
    handleChangeInput(assumedHousePrice, 'assumedHousePrice');
  };

  const productR = getFilteredProducts(products, inputs, lenderSVR, 'REMORTGAGE');
  const productRR = getFilteredProducts(products, inputs, lenderSVR, 'REDEEM_REMORTGAGE');

  const redeemRemortgageLTV = formatPercentage((inputs.outstandingBalance + (inputs.equityPercentage / 100 * inputs.currentValue)) / inputs.currentValue);
  const remortgageLTV = formatPercentage(inputs.outstandingBalance / inputs.currentValue);

  return (
    <>
      <Helmet>
        <title>Anji - your remortgage options</title>
        <meta name="description" content="Anji - your remortgage options" />
        <meta name="robots" content="noindex, follow" />
      </Helmet>
      <Breadcrumbs step={0} />
      <div className="remortgageOptions layout-content container">
        {productR ? (
          <>
            <div className="post-alert">
              <Collapse accordion>
                <Panel headerClass="post-alert-header" header="Mortgages and COVID-19">
                  <p className="post-alert-title">Due to coronavirus, there could be fewer mortgages available to you than usual.</p>
                  <p>Many lenders have tightened their criteria, making it harder for borrowers with lower equity or deposits to get a new deal. Many lenders have lowered the maximum loan to value ratio (LTV) they will offer - some will currently only lend up to a maximum LTV of 60%, so you would need a 40% deposit.</p>
                  <p>We update the mortgage deals we compare regularly however there have been many changes and we expect this to continue over the next weeks and months. This may lead to deals being withdrawn or changed after you are shown it.</p>
                  <p className="post-alert-title">There are also other impacts of coronavirus that mean the mortgage process might take a little longer now:</p>
                  <p>Where possible, lenders will use an automated or ‘desktop’ valuation. But, if your new mortgage needs a physical valuation, you’ll have to wait until social distancing guidelines have been relaxed. </p>
                  <p>Some lenders have fewer staff working at the moment, so the application process may be slower than usual.</p>
                  <p>Documents like house deeds generally need signatures to be witnessed, which could be more difficult to arrange at the moment because of social distancing measures.</p>
                  <p>If you need to remortgage urgently, it’s worth speaking to a broker for free.</p>
                </Panel>
              </Collapse>
            </div>
            <h1 className="title-primary">Your Remortgage Options</h1>
            <div className="subtitle-secondary row-separationSmall">We estimate a True Cost of <strong className="highlight">£{intToCurrency(productR.doNothing.totalCost)}</strong> if you do nothing and stay on your current mortgage.<br />Alternatively you could Remortgage or Remortgage and repay the Equity Loan.</div>
            <div className="card row-separationMedium">
              <div className="card-wrapper">
                <Collapse accordion>
                  <Panel headerClass="card-title" header="Adjust Market assumptions">
                    <div className="remortgageOptions-assumptions">
                      <div className="row row-gutterEnormous">
                        <div className="col col-desktop6 col-tablet12">
                          <p className="remortgageOptions-assumptions-title">House price at the end of 5 years <strong className="highlight">£{intToCurrency(inputs.assumedHousePrice)}</strong></p>
                          <Slider
                            value={assumedHousePriceRate}
                            onChange={handleChangeAssumedHousePrice}
                            min={-10}
                            max={30}
                            step={1}
                            tooltip={false}
                            handleLabel={`${assumedHousePriceRate}%`}
                          />
                          <p className="rangeslider-legend">
                            <span>-10%</span>
                            <span>+30%</span>
                          </p>
                        </div>
                        <div className="col col-desktop6 col-tablet12">
                          <p className="remortgageOptions-assumptions-title">RPI assumption</p>
                          <Slider
                            value={inputs.assumedRPI}
                            onChange={(value) => handleChangeInput(value, 'assumedRPI')}
                            min={0}
                            max={10}
                            step={1}
                            tooltip={false}
                            handleLabel={`${inputs.assumedRPI}%`}
                          />
                          <p className="rangeslider-legend">
                            <span>0%</span>
                            <span>10%</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </Panel>
                </Collapse>
              </div>
            </div>
            <div className="row row-separationHuge row-gutterEnormous">
              <div className="col col-desktop6 col-tablet12">
                <h3 className="remortgageOptions-title">Remortgage</h3>
                <Card product={productR.product} inputs={inputs} result={productR.remortgage} option='remortgage' />
              </div>
              <div className="col col-desktop6 col-tablet12">
                <h3 className="remortgageOptions-title">Remortgage and repay the equity loan</h3>
                {productRR ? (
                  <Card product={productRR.product} inputs={inputs} result={productRR.redeemRemortgage} option='redeem'/>
                ) : (
                  <div className="remortgageOptions-empty">
                    Sorry, based on the estimated required loan to value (LTV) of {redeemRemortgageLTV} to redeem the Equity Loan, we cannot find any products for you at this point. You could speak to an advisor about whether a partial repayment might be possible
                  </div>
                )}
              </div>
            </div>
            <div className="remortgageOptions-bullets">
              <h3 className="remortgageOptions-title">How does this calculator work?</h3>
              <div className="row row-gutterEnormous row-separationBig">
                <div className="col col-desktop6 col-mobile12">
                  <Collapse className="listGeneric" accordion>
                    <Panel header="What does this calculator show?">
                      This calculator is showing two options: (1) replacing your current mortgage (but leaving the Equity Loan outstanding); and (2) replacing your current mortgage and borrowing extra to repay
                      your Equity Loan. For each of these options, we search the market to find the cheapest product (based on initial fixed rate) available to you.
                      This calculator only considers 5 year fixed rate remortgage products. This calculator does not consider your personal circumstances to determine eligibility.
                    </Panel>
                    <Panel header="What are the ‘market assumptions’?">
                      To illustrate the cost of the different options, we need to estimate how much you will end up paying on your Equity Loan. The repayment amount on the Equity Loan
                      is a percentage of the market value of your home <i>at the time of repayment</i>; therefore the first market assumption is how much your home is worth 5 years from today.
                      The second assumption is the average inflation (RPI) rate over the next 5 years. This is used to estimate how much interest you’ll pay because the Equity Loan interest is linked to inflation.
                    </Panel>
                    <Panel header="What are the two options shown?">
                      If you currently have an Equity Loan and you are coming up to the end of your initial rate on your mortgage, there are two options to consider. The first is to just
                      remortgage to avoid going onto your lender’s SVR which is normally more expensive. The second option is to remortgage and borrow extra to pay off the Equity Loan.
                      This could be a good option if you think, for example, that house prices will go up a lot over the next few years.
                    </Panel>
                  </Collapse>
                </div>
                <div className="col col-desktop6 col-mobile12">
                  <Collapse className="listGeneric" accordion>
                    <Panel header="How are savings calculated?">
                      We estimate the ‘true cost’ of the different options you are shown - doing nothing, remortgaging and remortgaging along with repaying the Equity Loan. The true cost is the
                      estimate of what you’ll pay in each option over the next 5 years (assuming that you repay entirely at the end of the 5  year period). The savings are calculated as the
                      difference between doing nothing and the various remortgage options.
                    </Panel>
                    <Panel header="What are the eligibility criteria for the products?">
                      Each lender will have different eligibility criteria and this calculator just shows you the different products on the market without assessing your individual eligibility.
                      The products on this calculator are selected based on LTV and the product with the lowest initial fixed rate is shown. To determine whether you are eligible for the
                      selected product, you should proceed to seek advice from our mortgage advice partner - Habito.
                    </Panel>
                    <Panel header="Why am I not shown one or both of the options?">
                      If you don’t have the option to remortgage and repay the Equity Loan, it is because there are no products available that meet your required LTV once the Equity Loan repayment
                      is included, however there are products allowing you to just replace your current mortgage. If no options are shown, it’s because the LTV on your current mortgage is higher
                      than anything currently available on the market. Note these calculations depend on your estimate of your home value - if your home is actually worth more, there might be some products available to you.
                    </Panel>
                  </Collapse>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="remortgageOptions-noProducts">
            <h1 className="title-primary">Your Remortgage Options</h1>
            <p className="row-separationBig">Sorry, based on the estimated required loan to value of <span className="highlight">{remortgageLTV}</span> we cannot find any remortgage products for you at this point.</p>
            <img className="row-separationBig" src={habito} alt="habito" />
            <p><strong>Need some help?</strong> Speak to a mortgage expert for free help and advice. We'll pass you onto Habito - a broker that’s rated excellent from over 4,000 customer reviews</p>
            <a href="https://www.habito.com/" rel="noopener noreferrer" target="_blank" className="row-separationHuge button-success button-big">take me to habito</a>
          </div>
        )}
      </div>
    </>
  );
};

RemortgageOptions.propTypes = {
  inputs: inputsTypes,
  lenderSVR: lenderSVRTypes.isRequired,
  products: productsTypes,
  actions: PropTypes.shape({
    changeInput: PropTypes.func,
  }),

};

RemortgageOptions.defaultProps = {
  inputs: inputsDefaultTypes,
  products: productsDefaultTypes,
  actions: {
    changeInput: () => {},
  },
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    changeInput,
  },
  dispatch)
});

const mapStateToProps = (state) => ({
  inputs: state.inputs,
  lenderSVR: state.lenderSVR,
  products: state.products,
});

export default connect(
  mapStateToProps, mapDispatchToProps
)(RemortgageOptions);
