import React, { useState, createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import Breadcrumbs from './Breadcrumbs';


export const FormContext = createContext(null);

const MultiStepForm = ({ children, url }) => {
  const { slug } = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const [error, setError] = useState(false);
  const [errorForm, setErrorForm] = useState(false);
  const [warning, setWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');

  useEffect(() => {
    if (slug) setCurrentStep(parseInt(slug, 10));
  }, [currentStep, slug]);


  const renderNextButton = () => (
    currentStep + 1 === children.length
      ? <Link className="multiStepForm-next no-underline" to={url} />
      // eslint-disable-next-line
      : <button className="multiStepForm-next no-underline" type="button" onClick={() => setCurrentStep(currentStep + 1)} />
  );

  const renderPrevButton = () => (
    currentStep === 0
      ? null
      // eslint-disable-next-line
      : <button className="multiStepForm-prev no-underline" type="button" onClick={() => setCurrentStep(currentStep - 1)} />
  );

  let className = 'multiStepForm';
  className += errorForm ? ' errorForm' : '';
  className += error ? ' error' : '';
  className += warning ? ' warning' : '';

  return (
    <FormContext.Provider value={{
      error, setError, setErrorForm, setWarning, setWarningMessage
    }}
    >
      <div className={className}>
        <Breadcrumbs step={currentStep + 1} length={children.length} />
        <div className="flash">{warningMessage}</div>
        <div className="multiStepForm-container">
          {children[currentStep]}
          {renderPrevButton()}
          {renderNextButton()}
        </div>
      </div>
    </FormContext.Provider>
  );
};

MultiStepForm.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  url: PropTypes.string
};

MultiStepForm.defaultProps = {
  children: null,
  url: ''
};

export default MultiStepForm;
