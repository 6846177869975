import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import {
  productsTypes
} from '../types';

import { intToCurrency, formatPercentage } from '../lib/formats';

const ProductDetail = ({
  result, product, setModalToOpen
}) => {
  const fixedAmountFee = product.fixedAmountFee ? `£${intToCurrency(product.fixedAmountFee)}` : formatPercentage(product.variableRate * result.mortgageBalance);
  return (
    <div className="modal">
      <div className="modal-wrapper productsDetail">
        <button className="modal-close" type="button" aria-label="close" onClick={() => setModalToOpen(-1)} />
        <div className="productsDetail-list">
          {product.lender
                && (
                <img
                  className="productsDetail-list-logo"
                  src={require(`../images/products/${product.lender}.svg`)}
                  alt={product.lender}
                />
                )}
          <ul>
            <li>
              <div>
                <p>Mortgage amount</p>
              </div>
              <div className="productsDetail-list-title">£{intToCurrency(result.mortgageBalance)}</div>
            </li>
            <li>
              <p>True cost</p>
              <p className="productsDetail-list-title">£{intToCurrency(result.totalCost)}</p>
            </li>
            <li>
              <div>
                <p>Monthly payment</p>
                <p className="productsDetail-list-subtitle">
                  For the first {product.initialPeriodMonths} months
                </p>
              </div>
              <div className="productsDetail-list-title">£{intToCurrency(result.mortgagePayments)}</div>
            </li>
            <li>
              <div>Subsequent monthly payment</div>
              <div className="productsDetail-list-title">£{intToCurrency(result.subsequentVariableRate)}</div>
            </li>
          </ul>
          <ul>
            <li>
              <div>Initial rate</div>
              <div className="productsDetail-list-title">{formatPercentage(product.initialRate)}</div>
            </li>
            <li>
              <div>Savings</div>
              <div className="productsDetail-list-title">£{intToCurrency(result.saving)}</div>
            </li>
            <li>
              <div>Subsequent variable rate</div>
              <div className="productsDetail-list-title">{formatPercentage(product.variableRate)}</div>
            </li>
            <li>
              <div>Setup fees</div>
              <div className="productsDetail-list-title">{fixedAmountFee}</div>
            </li>
          </ul>
        </div>
        <div className="productsDetail-habito">
          <p>That’s why we’re passing you onto Habito - a broker that’s rated excellent from over 4,000 customer reviews</p>
          <ReactGA.OutboundLink
            eventLabel="habitoClick"
            to="https://www.habito.com/"
            target="_blank"
            className="row-separationHuge button-success button-big"
            rel="noopener noreferrer"
          >
            take me to habito
          </ReactGA.OutboundLink>
        </div>
        <div className="productsDetail-list-disclaimer">
          <div className="row">
            <div className="col col-desktop12 col-mobile12">
              <p className="productsDetail-list-disclaimer-title">Representative Example</p>
              <p>
                Repayment mortgage of £{intToCurrency(result.mortgageBalance)} with 240 monthly repayments. Initial fixed rate {formatPercentage(product.initialRate)},
                costing £{intToCurrency(result.mortgagePayments)} p/m for {product.initialPeriodMonths} months. At the end of the initial period mortgage reverts to Standard
                Variable Rate {formatPercentage(product.variableRate)}, costing £{intToCurrency(result.subsequentVariableRate)} p/m for {240 - product.initialPeriodMonths} months.
                True cost of this mortgage is £{intToCurrency(result.totalCost)} based on payments over the first 5 years and repayment at the end of that. This includes interest, repayments
                and any fees.
              </p>
              <p className="highlight">Your home may be repossed if you do not keep up repayments on your motgage.</p>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

ProductDetail.propTypes = {
  product: productsTypes.isRequired,
  setModalToOpen: PropTypes.func.isRequired,
  result: PropTypes.shape({
    saving: PropTypes.number,
    totalCost: PropTypes.number,
    mortgageBalance: PropTypes.number,
    mortgagePayments: PropTypes.number,
    initialRate: PropTypes.number,
    purchaseDate: PropTypes.string,
    subsequentVariableRate: PropTypes.number
  }).isRequired,
};

export default ProductDetail;
