import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import logo from '../images/anji-dark.svg';

const Breadcrumbs = ({ step, length }) => (
  <div className="breadcrumbs">
    {step ? (
      <div className="breadcrumbs-status">
        <div className="breadcrumbs-progress" style={{width: `${step / length * 100}%`}} />
      </div>
    ) : null}
    <div className="breadcrumbs-content container">
      <img src={logo} alt="anji" />
      <Link to="/" className="breadcrumbs-close no-underline" />
    </div>
  </div>
);

Breadcrumbs.propTypes = {
  step: PropTypes.number,
  length: PropTypes.number.isRequired
};

Breadcrumbs.defaultProps = {
  step: 1,
};

export default Breadcrumbs;
