import React, {
  useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FormContext } from '../MultiStepForm';
import Select from '../../decorators/withSelectMe';
import Input from '../../decorators/withInput';
import { monthsArray, monthDiff } from '../../lib/dateUtils';
import {
  inputsTypes, inputsDefaultTypes
} from '../../types';

const Step1 = ({ handleChangeSelect, handleChangeInput, inputs }) => {
  const history = useHistory();

  const handleAlreadyEnded = () => {
    handleChangeInput(new Date().getMonth() + 1, 'initialRateEndDateMonth');
    handleChangeInput(new Date().getFullYear() - 1, 'initialRateEndDateYear');
    history.push('/search/7');
  };

  const { setErrorForm, setWarning, setWarningMessage } = useContext(FormContext);

  const warningMessage = () => (
    <p>It&apos;s great you&apos;re planning ahead! We’ll show you some remortgaging options from the <strong>end of your initial rate period</strong>, but please be aware that products might change by the time you come to apply. Remortgaging before this time might incur early repayment charges from your existing lender.</p>
  );

  useEffect(() => {
    if (inputs.initialRateEndDateMonth && inputs.initialRateEndDateYear) {
      setErrorForm(false);
    } else {
      setErrorForm(true);
    }

    if (
      monthDiff(new Date(), new Date(inputs.initialRateEndDateYear, inputs.initialRateEndDateMonth)) > 6
    ) {
      setWarning(true);
      setWarningMessage(warningMessage);
      window.scrollTo(0, 0);
    } else {
      setWarning(false);
    }
  }, [inputs.initialRateEndDateMonth, inputs.initialRateEndDateYear]);

  return (
    <article className="steps">
      <h1 className="steps-title">When does your initial mortgage rate period end?</h1>
      <p className="steps-subtitle">For example, April 2021</p>
      <div className="steps-form row row-gutterTiny">
        <div className="col col-desktop8 col-mobile12">
          <div className="form-field">
            <Select
              options={monthsArray}
              value={inputs.initialRateEndDateMonth}
              onChange={(e) => handleChangeSelect(e, 'initialRateEndDateMonth')}
            />
          </div>
        </div>
        <div className="col col-desktop4 col-mobile12">
          <Input
            placeholder="Year"
            name="initialRateEndDateYear"
            onChange={handleChangeInput}
            type="tel"
            format="number"
            value={inputs.initialRateEndDateYear}
          />
        </div>
      </div>
      <button type="button" className="fakeLink" onClick={handleAlreadyEnded}>It’s already ended</button>
    </article>
  );
};

Step1.propTypes = {
  inputs: inputsTypes,
  handleChangeSelect: PropTypes.func,
  handleChangeInput: PropTypes.func
};


Step1.defaultProps = {
  inputs: inputsDefaultTypes,
  handleChangeSelect: () => {},
  handleChangeInput: () => {},
};

const mapStateToProps = (state) => ({
  inputs: state.inputs,
});

export default connect(
  mapStateToProps
)(Step1);
