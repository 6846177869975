import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../Layout';
import mainImg from '../../images/article2-full.jpg';

const Covid = () => (
  <Layout>
    <Helmet>
      <title>When is Help to Buy ending and other changes you need to know</title>
      <meta name="description" content="Help to Buy is scheduled to come to an end. Find out when changes come into place and your other options for first time buyers." />
    </Helmet>
    <article className="post">
      <img src={mainImg} alt=">When is Help to Buy ending" />
      <div className="container post-wrapper">
        <h1 className="title-main">When is Help to Buy ending and other changes you need to know</h1>
        <div className="post-content">
          <p className="post-subtitle">Help to Buy is scheduled to come to an end. Find out when changes come into place and your other options for first time buyers.</p>
          <p>The Help to Buy: Equity Loan has supported thousands of people in taking their first step onto the property ladder. As such the scheme is generally considered very successful. However there are plans to change it from 2021 to better target the money to help those who need it most. Of course, ultimately the scheme has to end as well and this is currently scheduled for 2023. However this doesn’t mean first time buyers will be left without any support - read below to find out more.</p>
          <p className="post-title">How is Help to Buy changing in 2021</p>
          <p>From a recent review of the scheme it was found that 19% of Help to Buy borrowers are not first time buyers and 31% could have afforded to buy a home without it. To make sure the scheme helps those who need it most, there are 2 changes coming into place from April 2021.</p>
          <p className="post-subtitle">Only available for first time buyers</p>
          <p>From April 2021 only first time buyers will be able to get a Help to Buy: Equity Loan. In order to reserve a Help to Buy property, you will have to make a legal declaration that you meet the definition of a first time buyer.</p>
          <p>The definition of first time buyer is the same as the one used for <a href="https://www.gov.uk/government/publications/stamp-duty-land-tax-relief-for-first-time-buyers-guidance-note" target="_blank" rel="noopener noreferrer">Stamp Duty calculations</a>. It can be summarised as someone who has never owned a ‘major interest’ in any freehold or leasehold property anywhere in the world. Understanding ‘major interest’ is quite a technical area, but it means, for example, even if you’ve inherited part of a property, you would not be counted as a first time buyer.</p>
          <p>Furthermore, if you are buying jointly with someone else (or more than one person), each and every person must meet the first time buyer definition.</p>
          <p className="post-subtitle">Regional price caps</p>
          <p>Similarly, to make sure Help to Buy is being used to help those who couldn’t otherwise afford to buy, there are going to be upper limits on the value of the property depending on the region you’re in.</p>
          <table className="post-table left">
            <tr>
              <th>Region</th>
              <th>Full purchase price cap</th>
            </tr>
            <tr>
              <td>East</td>
              <td>£407,000</td>
            </tr>
            <tr>
              <td>East Midlands</td>
              <td>£261,900</td>
            </tr>
            <tr>
              <td>London</td>
              <td>£600,000</td>
            </tr>
            <tr>
              <td>North East</td>
              <td>£186,100</td>
            </tr>
            <tr>
              <td>North West</td>
              <td>£224,400</td>
            </tr>
            <tr>
              <td>South East</td>
              <td>£437,600</td>
            </tr>
            <tr>
              <td>South West</td>
              <td>£349,000</td>
            </tr>
            <tr>
              <td>West Midlands</td>
              <td>£255,600</td>
            </tr>
            <tr>
              <td>Yorkshire and The Humber</td>
              <td>£228,100</td>
            </tr>
          </table>
          <p>Prior to April 2021 the maximum house price was £600,000 throughout England. After the change it is set at 1.5 times the average first time buyer house price for each region (as shown in the table above). The percentage size of the government contribution remains unchanged - that is, an Equity Loan up to 20% outside of London and up to 40% in London.</p>
          <p>Based on the current guidance, if you’re planning to purchase a Help to Buy home above these values, the property must have been built by 31 December 2020 and completion of the purchase must take place before 31 March 2021.</p>
          <p className="post-title">When will Help to Buy end?</p>
          <p>The new 2021 Help to Buy scheme is scheduled to run for 2 years, implying the whole scheme should end in 2023. For households currently saving for a deposit and planning to purchase in a few years, this is of course disappointing. Even if 95% mortgages are available at that time (which isn’t always the case), lender affordability calculations mean households will have to prove a considerably higher income than if they bought with Help to Buy.</p>
          <p>However Help to Buy has been the government’s flagship home ownership support scheme for many years and the amount of money allocated to it is a big dent in the budget. In total, by March 2023 it is expected that the government will have loaned £29 billion through the scheme, and depending on how quickly people pay it back, it could be 2048 before it is all paid back. It is in this context that the scheme must end. However this will not leave first time buyers without any support, in fact there are schemes already in place and new policies being developed to help fill the gap.</p>
          <p className="post-title">So what are the alternatives?</p>
          <p className="post-subtitle">Shared ownership</p>
          <p>Shared ownership allows people to buy a portion of a home (usually between 25% and 75%) and pay a subsidised rent on the remainder. As the household income improves, they have the option to ‘staircase’ and gradually increase their ownership share. It is not a new scheme, having been offered through Housing Associations since the late 70s. However there is a renewed impetus to promote its benefits and make it available to many more households looking to get on the property ladder.</p>
          <p>In the past there has been a lack of shared ownership availability. This is because a relatively small number shared ownership properties were built every year (under 10,000) and those that were built were prioritised for people who lived and worked in the local area. Combined with other eligibility criteria, such as maximum household income, it could not become a mainstream option.</p>
          <p>However in recent years the government has taken significant measures to ease this. The income cap has been increased and there is significantly more funding available to construct shared ownership properties. Furthermore, the industry is looking at ways to improve the experience, for example, by making it much easier and cheaper to staircase.</p>
          <p className="post-subtitle">First Homes</p>
          <p>This is a very recent development with the government just asking for industry feedback early 2020. However it is clear from the initial materials published that the government has ambitions to make this a large scale and impactful scheme. Essentially it is a &apos;Discounted Market Sale Housing&apos; scheme to be offered in England; this means that properties will be sold (to eligible buyers) at a 30% discount to open market value. </p>
          <p>The intended purpose of this is to create housing that is permanently affordable, therefore this 30% discount will ‘stay with the property’, in other words, when the property is sold, it again has to be sold at a 30% discount. It will not be open to everyone - details are still to be finalised, but it is likely to be only available to first time buyers and will be prioritised for people already living in the local area, and possibly other groups as well, such as key workers.</p>
          <p>It is still relatively early in the development, so we do not know when it will become available, however from the initial details it seems to have the potential to fill a large part of the gap left by the closure of Help to Buy.</p>
        </div>
      </div>
    </article>
  </Layout>
);

export default Covid;
