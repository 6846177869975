import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Layout from '../Layout';
import mainImg from '../../images/article0-full.jpg';
import ogImg from '../../images/article0-fb.jpg';

const Covid = () => (
  <Layout>
    <Helmet>
      <title>Should I remortgage to pay off the Help to Buy Equity Loan?</title>
      <meta name="description" content="We take a detailed look at remortgaging to pay off your Equity Loan - who it might be good for and how to go about doing it." />
      <meta name="og:image" content={ogImg} />
      <meta name="og:title" content="Should I remortgage to pay off the Help to Buy Equity Loan?" />
      <meta name="og:description" content="We take a detailed look at remortgaging to pay off your Equity Loan - who it might be good for and how to go about doing it." />
    </Helmet>
    <article className="post">
      <img src={mainImg} alt="Remortgage to pay off the Help to Buy" />
      <div className="container post-wrapper">
        <h1 className="title-main">Should I remortgage to pay off the Help to Buy Equity Loan?</h1>
        <div className="post-content">
          <p className="post-subtitle">We take a detailed look at remortgaging to pay off your Equity Loan - who it might be good for and how to go about doing it.</p>
          <p className="post-title">Introduction</p>
          <p>If you’ve taken out a Help to Buy Equity Loan then you know one of its most beneficial features is the 5 year interest free period. Many homeowners take out an Equity Loan planning to pay it off before this interest free period ends.</p>
          <p>Of course being able to repay the Equity Loan from savings is the ideal outcome, however, given the average loan size is just shy of £50,000, this is not a realistic proposition for most households.</p>
          <p>In this case the best bet is to look at remortgage options - this could allow you to ‘staircase’ which means borrowing more from your mortgage to repay the Equity Loan. In this article we look at the key considerations in assessing whether you should repay the Equity Loan by remortgage and steps to do so.</p>
          <p className="post-title">Recap on the Help to Buy Equity Loan</p>
          <p>The Help to Buy Equity Loan contributes up to 20% of the value of a home (40% in London) if purchasing an eligible new build property. The scheme is open to all buyers, both first time and otherwise, and has been widely taken up - around 250,000 households as of September 2019.</p>
          <p>The loan has a 5 year interest free period - this means you are not obliged to make any payments for the first 5 years (other than a £1 monthly management fee). After that the interest rate starts at 1.75% (starting from year 6 of the loan) and increases linked to inflation. The formula for the inflation linkage is RPI (retail price index) + 1%, so to illustrate how this works (assuming RPI is 3%):</p>
          <table className="post-table">
            <tr>
              <th>Year</th>
              <th>Assumed RPI (3%) + 1%</th>
              <th>Equity Loan interest rate</th>
            </tr>
            <tr>
              <td>1 - 5</td>
              <td>4%</td>
              <td>0%</td>
            </tr>
            <tr>
              <td>6</td>
              <td>4%</td>
              <td>1.75%</td>
            </tr>
            <tr>
              <td>7</td>
              <td>4%</td>
              <td>1.82%</td>
            </tr>
            <tr>
              <td>8</td>
              <td>4%</td>
              <td>1.89%</td>
            </tr>
            <tr>
              <td>9</td>
              <td>4%</td>
              <td>1.97%</td>
            </tr>
            <tr>
              <td>10</td>
              <td>4%</td>
              <td>2.05%</td>
            </tr>
          </table>
          <p>Next, how are the repayments of the Equity Loan calculated? Here the most important feature to notice is the Equity nature of it. Think of this like the government owning a stake in your home. If this stake is 20%, you need to repay 20% of the current market value of the home. This could be either more or less than the amount you borrowed in the first place.</p>
          <p className="post-title">Repaying the Equity Loan by remortgaging</p>
          <p>The Help to Buy Equity Loan has a term of 25 years; this means you have up to 25 years to pay it off (or when you sell your home). However the question of when it is best to pay off the loan depends on several factors, including:</p>
          <ol>
            <li>1. The loan-to-value on your mortgage</li>
            <li>2. Personal circumstances and the mortgage deals that are available to you.</li>
            <li>3. The evolution of market parameters (house prices and inflation rates)</li>
          </ol>
          <p className="post-subtitle">Mortgage Loan-to-Value</p>
          <p>The loan to value (LTV) is one of the most important determinants of whether you can get a mortgage or not. It measures the amount you are seeking to borrow against the market value of the property. The lower the LTV, the less risk it is for the lender and therefore the better rates you are likely to get.</p>
          <p>Let’s look at an example to see how this could work. Based on property that was initially bought for £300,000, with a mortgage of £225,000 and an Equity Loan of £60,000 (20%), the table below shows the required LTV based on different market values for your home.</p>
          <table className="post-table noFixed">
            <tr>
              <td />
              <td>£270,000</td>
              <td>£300,000</td>
              <td>£330,000</td>
            </tr>
            <tr>
              <th>Mortgage outstanding</th>
              <td>£194,000</td>
              <td>£194,000</td>
              <td>£194,000</td>
            </tr>
            <tr>
              <th>Repayment of Equity Loan (20%)</th>
              <td>£54,000</td>
              <td>£60,000</td>
              <td>£66,000</td>
            </tr>
            <tr>
              <th>Remortgage amount required</th>
              <td>£248,000</td>
              <td>£254,000</td>
              <td>£260,000</td>
            </tr>
            <tr>
              <th>Remortgage LTV</th>
              <td>92%</td>
              <td>85%</td>
              <td>79%</td>
            </tr>
          </table>
          <p className="italic">This assumes a 25 year interest and repayment mortgage with an initial rate of 3.5% fixed for 5 years.</p>
          <p>In this example, your current mortgage outstanding would have reduced to £194k (from £225,000 initially) from the repayments you’ve been making over the past 5 years. The ‘Remortgage Amount Required’ is the amount to pay back the current mortgage plus the Equity Loan. The ‘Repayment of Equity Loan’ is calculated as 20% of the value of the property based on today’s market prices.</p>
          <p>Based on this calculation, you can then see the required LTV in different scenarios - if your house price has risen 10%, the LTV required is under 80%, whereas if your house price has fallen 10%, you’ll need a mortgage over 92% LTV. Of course in the former scenario it is likely you’ll get more mortgage offers and better rates.</p>
          <p className="post-subtitle">Personal circumstances</p>
          <p>Other than the LTV, lenders look at your personal finances to establish whether you meet their criteria. If your initial purchase was made with a mainstream lender (such as one of the big banks), it is likely you had a clean credit file at that time. As long as your file hasn’t deteriorated since then, your first option might be to consider remortgaging with your existing mortgage lender.</p>
          <p>However if you’ve missed payments (on your mortgage or any other credit arrangement or bill), it may be that your credit score has deteriorated. In this case you’ll be best off speaking to a specialist mortgage broker. They can provide you with personalised advice and the specialists know which lenders to use to maximise your chances of getting approved.</p>
          <p>The other big point to consider is loan-to-income (LTI) multiple. Most lenders limit LTI multiples to 4.5x, in other words they won’t lend more than 4.5x your gross household income. When you initially bought, the Equity Loan was outside of this limit, however if you are now remortgaging to a higher amount, it could be that the LTI multiple starts to be a restriction.</p>
          <p className="post-subtitle">The evolution of market parameters</p>
          <p>The final consideration, and in many ways the hardest to judge, is how you expect house prices and inflation to evolve in the future.</p>
          <p>House prices are relevant because the Equity Loan repayment is a percentage of your home value at the time you choose to repay. If you expect your home to appreciate significantly in the coming years, for example due to local redevelopment or improved transport links, then the calculation leans more towards repaying the Equity Loan now. By doing so you’ll not have to pay away these gains and you effectively lock in 100% of the appreciation for yourself.</p>
          <p>Similarly inflation determines the interest rate on the Equity Loan once the 5 year interest free period ends. If you expect inflation (the RPI index) to be high, it is more likely you’ll be able to find a remortgage deal that is cheaper than what you expect to pay on the Equity Loan.</p>
          <p>These parameters are hard to estimate, but by using <Link to="/search">calculators</Link> you can understand their effect and better judge your options.</p>
          <p className="post-title">So what next?</p>
          <p>In this article we cover the different factors that you need to consider to decide whether to repay your Equity Loan by remortgaging. The best way to really understand these factors is to use a calculator such as <Link to="/search">ours</Link> and then by speaking to a mortgage broker experienced in dealing with Help to Buy remortgages.</p>
          <p>If you then choose to go ahead, there will be a few steps in the process:</p>
          <ul>
            <ol>
              <p className="post-title">1. Get a mortgage agreement in principle:</p>
              <p>this will give you a good idea of the mortgage you can get. The exact amount required will depend on the RICS valuation which will be conducted before a formal offer is made. You’ll also need this valuation report to assess the repayment amount on the Equity Loan.</p>
            </ol>
            <ol>
              <p className="post-title">2. Instruct a solicitor:</p>
              <p>even though you’re not buying a new property, you need a solicitor to help with the new mortgage and to make changes at Land Registry related to the Equity Loan repayment.</p>
            </ol>
            <ol>
              <p className="post-title">3. Initiate the repayment process with Target:</p>
              <p><a href="https://www.myfirsthome.org.uk/" target="_blank" rel="noopener noreferrer">Target</a> are appointed by Homes England to manage Help to Buy Equity Loans. They require a £200 administration fee to process the repayment.</p>
            </ol>
            <ol>
              <p className="post-title">4. Completion:</p>
              <p>After you’ve filled out their form, paid the fee and provided a RICS valuation, Target will send you a Redemption Letter stating exactly what needs to be repaid. You can then proceed with formalising the mortgage offer. Once that is done your solicitor will arrange for the remortgage funds to be paid directly to Target to settle your Equity Loan.</p>
            </ol>
          </ul>
        </div>
      </div>
    </article>
  </Layout>
);

export default Covid;
