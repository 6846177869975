import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { intToCurrency, formatPercentage } from '../lib/formats';
import ProductDetail from './ProductDetail';
import ReactGA from 'react-ga';

import {
  inputsTypes, inputsDefaultTypes
} from '../types';

const Card = ({ product, inputs, result , option }) => {
  const [modalToOpen, setModalToOpen] = useState(0);

  const handleModalClick = () => {
    setModalToOpen(1);
    ReactGA.modalview(option);
  }

  return (
    <>
      <div className="card">
        <div className="card-wrapper">
          <div className="card-header">
            <div className="popup-wrapper">
              <span>Savings</span>
              <div className="popup popup-right">
                <div className="popup-content">
                  <p>The difference in true cost between continuing on your current mortgage and Help to Buy: Equity Loan, compared to the option shown here.</p>
                </div>
              </div>
            </div>
            <img src={require(`../images/products/${product.lender}.svg`)} alt={product.lender} />
          </div>
          <p className="remortgageOptions-card-title"><small>£</small>{intToCurrency(result.saving)}</p>
          <div className="popup-wrapper">
            <span>True cost over 5 years <strong className="remortgageOptions-card-subtitle">£{intToCurrency(result.totalCost)}</strong></span>
            <div className="popup">
              <div className="popup-content">
                <p>The total cost of your mortgage and Help to Buy: Equity Loan over the next 5 years, including all fees and incentives (like cashback from lenders), and assuming everything is repaid at the end of this period.</p>
                <p>The payments on the Equity Loan are linked to market assumptions. You can change these assumptions in this calculator and see the effect on true cost in different options.</p>
              </div>
            </div>
          </div>
        </div>
        <ul className="remortgageOptions-card-list">
          <li>
            <div className="popup-wrapper">
              <span>Mortgage amount</span>
              <div className="popup popup-right">
                <div className="popup-content">
                  <p>The amount of the new mortgage you will take out. If just remortgaging, this will be roughly the same as your current mortgage. If remortgage and also repaying the Help to Buy: Equity Loan, this will be roughly the outstanding amount on your mortgage plus the Equity Loan.</p>
                </div>
              </div>
            </div>
            <p className="remortgageOptions-card-subtitle">£{intToCurrency(result.mortgageBalance)}</p>
          </li>
          <li>
            <div className="popup-wrapper">
              <span>Monthly payment</span>
              <div className="popup">
                <div className="popup-content">
                  <p>What your mortgage will cost each month during the initial period. After the initial period is over, your interest rate will almost certainly rise and these payments go up too.</p>
                </div>
              </div>
            </div>
            <p className="remortgageOptions-card-subtitle">£{intToCurrency(result.mortgagePayments)}</p>
          </li>
          <li>
            <div className="popup-wrapper">
              <span>Initial interest</span>
              <div className="popup popup-center-mobile">
                <div className="popup-content">
                  <p>During the initial period of your mortgage, you get a lower than standard interest rate. Think of it like an introductory offer from your lender.</p>
                </div>
              </div>
            </div>
            <p className="remortgageOptions-card-subtitle">{product && formatPercentage(product.initialRate)}</p>
          </li>
        </ul>
      </div>
      <div className="card-cta row-separationMedium right-aligned">
        <button onClick={() => handleModalClick()} type="button" className="button-success button-medium">more details</button>
      </div>
      {modalToOpen > 0
        && (
        <ProductDetail
          inputs={inputs}
          result={result}
          product={product}
          setModalToOpen={setModalToOpen}
        />
        )}
    </>
  );
};

Card.propTypes = {
  product: PropTypes.shape({
    lender: PropTypes.string,
    initialRate: PropTypes.number,
  }).isRequired,
  result: PropTypes.shape({
    saving: PropTypes.number,
    totalCost: PropTypes.number,
    mortgageBalance: PropTypes.number,
    mortgagePayments: PropTypes.number,
    initialRate: PropTypes.number,
  }).isRequired,
  inputs: inputsTypes,
};

Card.defaultProps = {
  inputs: inputsDefaultTypes,
};
export default Card;
