export const changeInput = (value, name) => ({
  type: 'INPUTS.CHANGE_INPUT',
  value,
  name
});

export const changeSelect = (value, name) => ({
  type: 'INPUTS.CHANGE_SELECT',
  value,
  name
});
