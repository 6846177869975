export default (state = [], action) => {
  switch (action.type) {
    case 'INPUTS.CHANGE_INPUT': {
      return {
        ...state,
        [action.name]: action.value
      };
    }
    case 'INPUTS.CHANGE_SELECT': {
      return {
        ...state,
        [action.name]: action.value
      };
    }
    default:
      return state;
  }
};
