import React from 'react';
import { Helmet } from 'react-helmet';
import Claim from '../Claim';
import Bullets from '../Bullets';
import Layout from '../Layout';
import Lenders from '../Lenders';
import MainArticle from '../MainArticle';
import Articles from '../Articles';

const Home = () => (
  <Layout showCovid>
    <Helmet>
      <title>Anji - helping you get the best mortgage rates</title>
      <meta name="description" content="Anji gives you guides, calculators and gives you access to the best mortgage rates for your Help to Buy Equity Loan" />
    </Helmet>
    <Claim />
    <Bullets />
    <Lenders />
    <MainArticle />
    <Articles />
  </Layout>
);

export default Home;
