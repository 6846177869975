import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const Tabs = ({ currentTab, children }) => {
  const verifiedTab = currentTab < children.length ? currentTab : 0;
  const [activeTab, setActiveTab] = useState(verifiedTab);

  useEffect(() => {
    setActiveTab(verifiedTab);
  }, [currentTab]);

  function renderTabsList() {
    return children.map((child, i) => (
      <li
        onClick={() => setActiveTab(i)}
        className={activeTab === i ? 'active' : ''}
        key={child.props.title}
      >
        {child.props.title}
      </li>
    ));
  }

  function renderTabsContent() {
    return children.map((child, i) => (
      <li
        className={activeTab === i ? 'active' : ''}
        key={child.props.title}
      >
        {child.props.children}
      </li>
    ));
  }
  return (
    <div className="tabs">
      <ul className="tabs-list">
        {renderTabsList()}
      </ul>
      <ul className="tabs-content">
        {renderTabsContent()}
      </ul>
    </div>
  );
};

Tabs.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  currentTab: PropTypes.number
};

Tabs.defaultProps = {
  children: null,
  currentTab: 0
};

export default Tabs;
