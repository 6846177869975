import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Layout from '../Layout';
import mainImg from '../../images/article3-full.jpg';

const Covid = () => (
  <Layout>
    <Helmet>
      <title>Who are Target HCA?</title>
      <meta name="description" content="What is Target HCA and how are they related to your Help to Buy Equity Loan?" />
    </Helmet>
    <article className="post">
      <img src={mainImg} alt="Who are Target HCA" />
      <div className="container post-wrapper">
        <h1 className="title-main">Who are Target HCA?</h1>
        <div className="post-content">
          <p className="post-subtitle">What is Target HCA and how are they related to your Help to Buy Equity Loan?</p>
          <p>Agents, Homes England, your mortgage lender - if you’ve bought a home using the Help to Buy Equity Loan, you’ve dealt with quite a few different groups to get this far.</p>
          <p>However, you probably didn’t deal with Target at the time of buying, so you might be wondering why they have your details and how they are involved. In this article we’ll breakdown all the different groups involved in a Help to Buy purchase and the different roles they play.</p>
          <p>But first, who are Target? Target, or Target Servicing Limited, is a private company that has entered into an agreement with Homes England to deal with the customers of Help to Buy once their home purchase has completed. This covers things such as collecting interest payments, sending out letters and statements as well as one-off events such as handling the process when you want to repay your Equity Loan.</p>
          <p>Target’s website for the Help to Buy Equity Loan is: <a href="https://www.myfirsthome.org.uk" target="_blank" rel="noopener noreferrer">https://www.myfirsthome.org.uk</a>. If you’ve received any communication that you suspect could be fraudulent, you should contact Target using the details provided on their website.</p>
          <p>Looking at the other parties involved in a Help to Buy Equity Loan home purchase. </p>
          <p className="post-title">Help to Buy Agent</p>
          <p>Agents are appointed by Homes England primarily for the purpose of assessing the initial application from potential buyers. Despite the 5 year interest free period, it is still important to consider whether buyers will be able to maintain their mortgage and Help to Buy: Equity Loan - it is the Agent that undertakes this assessment. Once the Agent is satisfied with the application, they advise Homes England to proceed with the issuance of the Equity Loan. Customers should not have to pay Agents - they earn a fee from Homes England for each completed sale.</p>
          <p>There used to be 7 regionally based Agents however this has now been reduced to just 3 - Agent for the <a href="https://www.helptobuyagent3.org.uk/" target="_blank" rel="noopener noreferrer">South</a>, the <a href="https://www.helptobuyagent2.org.uk/" target="_blank" rel="noopener noreferrer">Midlands and London</a>, and for the <a href="https://www.helptobuyagent1.org.uk/" target="_blank" rel="noopener noreferrer">North</a>.</p>
          <p className="post-title">Homes England</p>
          <p>Homes England is a relatively new organisation, having been set up in January 2018. It is the successor to the Homes and Communities Agency (HCA) - if you took out an Equity Loan before 2018 you would have received documentation from HCA, but for new Loans, it will be from Homes England. They are a public body sponsored by the Ministry of Housing, Communities and Local Government and one of their major goals is to improve the quality and supply of housing in England.</p>
          <p>In the context of Help to Buy, they have been given responsibility by the Ministry of Housing, Communities and Local Government to deliver the scheme. That means they are the one’s actually paying out the Equity Loan money and they maintain contracts and relationships with all the other players in the ecosystem - the property developers, the Help to Buy Agents and with Target.</p>
          <p className="post-title">Ministry of Housing, Community and Local Government</p>
          <p>Community and Local Government (MHCLG) is the government department responsible for the Help to Buy Equity Loan scheme. Their strategic aim is to “deliver the homes the country needs”, through increasing home ownership and increasing housing supply. Help to Buy Equity Loan is the biggest housing programme run by the department.</p>
          <p>Although Homes England has the job of actually running the scheme, MHCLG have oversight and decide the policy behind the scheme. The actual money used to provide the Equity Loans comes from the department as well. Changes to the scheme, <Link to="/when-is-help-to-buy-ending">such as those scheduled to take place in 2021</Link>, are decided by MHCLG after reviewing the extent to which the scheme is effective in delivering their strategic goals.</p>
          <p className="post-title">Property Developer</p>
          <p>Help to Buy Equity Loan is only available on new build properties. The reason for this is that the scheme achieves two goals in parallel - one it helps prospective homeowners secure a mortgage and buy a property, but secondly it encourages more housing to be built by helping developers sell the properties they build.</p>
          <p>Large property developers sign up Homes England directly whilst smaller ones go through one of the Agents. As they build properties, they put forward the ones that are eligible for Help to Buy, typically on the basis of meeting the maximum price limit. Then when a Help to Buy borrower purchases one of the properties, the Equity Loan amount is paid directly to the developer.</p>
          <p className="post-title">Mortgage Lender</p>
          <p>In the Equity Loan documentation it talks about creating a mortgage on your property. However this is a second charge mortgage and there will be another lender, usually a bank, providing the main mortgage to purchase the home. As a technical side note - a mortgage is a document that is registered at the Land Registry stating that someone has some claim to the property, in this case, based on them having lent you money. So technically both the bank and Homes England are your ‘mortgage lenders’, but in reality it is understood to be talking about the bank providing the main mortgage.</p>
          <p>For purchases outside of London, the Help to Buy Equity Loan provides up to 20% of the price of the home. Home buyers are required to put down a minimum deposit of 5%, and the remaining 75% will come from the mortgage lender. When you complete the purchase you’ll be paying interest and repayments on the mortgage (and interest on the Equity Loan after the first 5 years). Buyers should consider their <Link to="/search">remortgage options</Link> just as they would if purchasing without Help to Buy - you should always try to stay on the best deal available and this is not going to be the lender’s standard variable rate (which is usually the rate if you don’t remortgage after the initial deal ends).</p>
        </div>
      </div>
    </article>
  </Layout>
);

export default Covid;
