import React, {
  useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormContext } from '../MultiStepForm';
import Select from '../../decorators/withSelectMe';
import {
  inputsTypes, inputsDefaultTypes, lenderSVRTypes
} from '../../types';

const Step5 = ({ handleChangeSelect, inputs, lenderSVR}) => {
  const lenderSelect = lenderSVR.map((l) => (
    {
      value: l.lender,
      label: l.lenderName,
    }
  ));

  const { setErrorForm } = useContext(FormContext);

  useEffect(() => {
    if (inputs.currentLender) {
      setErrorForm(false);
    } else {
      setErrorForm(true);
    }
  });

  return (
    <article className="steps">
      <h1 className="steps-title">Who is your mortgage with?</h1>
      <p className="steps-subtitle">For example, Barclays</p>
      <div className="steps-form">
        <div className="col col-desktop8 col-mobile12">
          <div className="form-field">
            <Select
              options={lenderSelect}
              value={inputs.currentLender}
              onChange={(e) => handleChangeSelect(e, 'currentLender')}
            />
          </div>
        </div>
      </div>
    </article>
  );
};

Step5.propTypes = {
  inputs: inputsTypes,
  handleChangeSelect: PropTypes.func,
  lenderSVR: lenderSVRTypes.isRequired
};


Step5.defaultProps = {
  inputs: inputsDefaultTypes,
  handleChangeSelect: () => {},
};

const mapStateToProps = (state) => ({
  inputs: state.inputs,
  lenderSVR: state.lenderSVR,
});

export default connect(
  mapStateToProps
)(Step5);
