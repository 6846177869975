import { monthDiff, eoMonth, nextEoMonth } from './dateUtils';
import PMT from './PMT';

const getLenderSVR = (lenders, lender) => lenders.find((l) => l.lender === lender).rate;
const renderFormulaResults = (
  {
    purchaseDateMonth,
    purchaseDateYear,
    purchasePrice,
    equityPercentage,
    currentValue,
    currentLender,
    outstandingBalance,
    initialRateEndDateMonth,
    initialRateEndDateYear,
    currentRate,
    assumedHousePrice,
    assumedRPI
  },
  lenderSVR,
  product
) => {
  const purchaseDate = eoMonth(new Date(purchaseDateYear, purchaseDateMonth - 1, 1));
  const initialRateEndDate = eoMonth(new Date(initialRateEndDateYear, initialRateEndDateMonth - 1, 1));
  const equityPercentageParsed = equityPercentage / 100;
  const currentRateParsed = currentRate / 100;
  const today = new Date();
  let formulaArray = [{}];
  const assumedRPIParsed = assumedRPI / 100;

  const variableFee = 0;

  const remainingTerm = monthDiff(eoMonth(today), eoMonth(purchaseDate, 300));
  const remainingFixedRateTerm = initialRateEndDate <= eoMonth(today)
    ? -1 * monthDiff(initialRateEndDate, eoMonth(today))
    : monthDiff(eoMonth(today), initialRateEndDate) + 1;
  const remainingTermToH2BInterest = monthDiff(purchaseDate, eoMonth(today)) > 60
    ? -1 * monthDiff(eoMonth(purchaseDate, 60), eoMonth(today))
    : monthDiff(eoMonth(today), eoMonth(purchaseDate, 60)) + 1;
  const helpToBuyRedemption = currentValue * equityPercentageParsed;
  const SVRRate = getLenderSVR(lenderSVR, currentLender);
  const FIRST = Math.max(remainingFixedRateTerm + 1, 1);
  const LAST = FIRST + 59;

  for (let i = 1; i <= LAST; i += 1) {
    const date = i > 1 ? nextEoMonth(formulaArray[i - 1].date) : eoMonth(today);
    const currentMortgageRate = date <= initialRateEndDate ? currentRateParsed : SVRRate;
    const RPI = (i - remainingTermToH2BInterest) <= 0
      ? 0
      : (1.01 + assumedRPIParsed) ** (Math.floor((i - remainingTermToH2BInterest - 1) / 12, 0));
    const mortgageBalanceDN = i > 1 ? formulaArray[i - 1].mortgageBalanceDN - formulaArray[i - 1].mortgagePaymentsDN + formulaArray[i - 1].mortgageBalanceDN * formulaArray[i - 1].currentMortgageRate / 12 : outstandingBalance;
    const mortgagePaymentsDN = PMT((currentMortgageRate / 12), (remainingTerm - i), (-1 * mortgageBalanceDN));
    const H2BInterest = purchasePrice * equityPercentageParsed * 1.75 / 100 * RPI / 12;
    const remortgageRateR = date <= initialRateEndDate ? currentRateParsed : product.initialRate;
    const mortgageBalanceR = i > 1 ? formulaArray[i - 1].mortgageBalanceR - formulaArray[i - 1].mortgagePaymentsR + formulaArray[i - 1].mortgageBalanceR * formulaArray[i - 1].remortgageRateR / 12 : outstandingBalance;
    const mortgagePaymentsR = PMT((remortgageRateR / 12), (remainingTerm - i), (-1 * mortgageBalanceR));
    const subsequentVariableRateR = PMT((product.variableRate / 12), (remainingTerm - i), (-1 * mortgageBalanceR));
    const remortgageRateRR = i <= remainingFixedRateTerm ? currentRateParsed : product.initialRate;
    const mortgageBalanceRR = i > 1
      ? formulaArray[i - 1].mortgageBalanceRR - formulaArray[i - 1].mortgagePaymentsRR + formulaArray[i - 1].mortgageBalanceRR * formulaArray[i - 1].remortgageRateRR / 12 + (i === FIRST ? helpToBuyRedemption : 0)
      : outstandingBalance + (i === FIRST ? helpToBuyRedemption : 0);

    const mortgagePaymentsRR = PMT((remortgageRateRR / 12), (remainingTerm - i), (-1 * mortgageBalanceRR));
    const subsequentVariableRateRR = PMT((product.variableRate / 12), (remainingTerm - i), (-1 * mortgageBalanceRR));

    formulaArray = [...formulaArray, {
      currentMortgageRate,
      mortgagePaymentsDN,
      H2BInterest,
      mortgageBalanceDN,
      remortgageRateR,
      mortgagePaymentsR,
      mortgageBalanceR,
      subsequentVariableRateR,
      remortgageRateRR,
      mortgagePaymentsRR,
      mortgageBalanceRR,
      subsequentVariableRateRR,
      date,
    }];
  }

  const dateReference = initialRateEndDate > eoMonth(today) ? initialRateEndDate : eoMonth(today);
  const currentRow = formulaArray.find((f) => f.date && f.date.getTime() === dateReference.getTime());
  const nextRow = formulaArray.find((f) => f.date && f.date.getTime() === nextEoMonth(dateReference).getTime());

  const sum = formulaArray
    .filter((fa, index) => index >= FIRST && index <= LAST)
    .reduce((acc, val) => ({
      doNothing: (acc.doNothing + val.mortgagePaymentsDN + val.H2BInterest),
      remortgage: (acc.remortgage + val.mortgagePaymentsR + val.H2BInterest),
      redeemRemortgage: (acc.redeemRemortgage + val.mortgagePaymentsRR)
    }), {
      doNothing: 0,
      remortgage: 0,
      redeemRemortgage: 0
    });
  const doNothing = sum.doNothing + formulaArray[LAST].mortgageBalanceDN + assumedHousePrice * equityPercentageParsed;
  const remortgage = sum.remortgage + formulaArray[LAST].mortgageBalanceR + assumedHousePrice * equityPercentageParsed + variableFee;
  const remortgageSaving = doNothing - remortgage;
  const redeemRemortgage = sum.redeemRemortgage + formulaArray[LAST].mortgageBalanceRR + variableFee;
  const redeemRemortgageSaving = doNothing - redeemRemortgage;

  return {
    doNothing: {
      totalCost: doNothing,
      mortgageBalance: currentRow.mortgageBalanceDN
    },
    remortgage: {
      totalCost: remortgage,
      saving: remortgageSaving,
      mortgageBalance: nextRow.mortgageBalanceR,
      mortgagePayments: nextRow.mortgagePaymentsR,
      subsequentVariableRate: nextRow.subsequentVariableRateR
    },
    redeemRemortgage: {
      totalCost: redeemRemortgage,
      saving: redeemRemortgageSaving,
      mortgageBalance: nextRow.mortgageBalanceRR,
      mortgagePayments: nextRow.mortgagePaymentsRR,
      subsequentVariableRate: nextRow.subsequentVariableRateRR,
    }
  };
};

export default renderFormulaResults;
