export default {
  products: [
    {
      lender: 'BARCLAYS',
      lenderName: 'Barclays Bank',
      productName: 'RETENTION 5 Year Fixed No Fee EMC Reward Rate Switch Only',
      purpose: 'RETENTION',
      productType: 'RESIDENTIAL',
      repaymentType: 'REPAYMENT',
      lTV: 0.9,
      initialPeriodMonths: 60,
      initialPeriodLimit: '2025-07-31',
      initialRate: 0.0235,
      initialRateType: 'FIXED',
      minTerm: 84,
      maxTerm: 420,
      variableRate: 0.0359,
      fixedAmountFee: 0,
      variableFee: null,
      minFinance: 5000,
      maxFinance: 2000000,
      minPropertyValue: 80000,
      location: 'ENGLAND_SCOTLAND_WALES'
    },
    {
      lender: 'VIRGIN_MONEY',
      lenderName: 'Virgin Money',
      productName: 'RETENTION HTB 5 Year Fixed No Fee',
      purpose: 'RETENTION',
      productType: 'RESIDENTIAL',
      repaymentType: 'REPAYMENT',
      lTV: 0.9,
      initialPeriodMonths: 60,
      initialPeriodLimit: '2025-09-01',
      initialRate: 0.0309,
      initialRateType: 'FIXED',
      minTerm: 84,
      maxTerm: 420,
      variableRate: 0.0434,
      fixedAmountFee: 0,
      variableFee: null,
      minFinance: 0,
      maxFinance: 450000,
      minPropertyValue: 80000,
      location: 'ENGLAND_SCOTLAND_WALES'
    },
    {
      lender: 'BARCLAYS',
      lenderName: 'Barclays Bank',
      productName: 'RETENTION 5 Year Fixed ¬£999 Fee EMC Reward Rate Switch Only',
      purpose: 'RETENTION',
      productType: 'RESIDENTIAL',
      repaymentType: 'REPAYMENT',
      lTV: 0.85,
      initialPeriodMonths: 60,
      initialPeriodLimit: '2025-07-31',
      initialRate: 0.019,
      initialRateType: 'FIXED',
      minTerm: 84,
      maxTerm: 420,
      variableRate: 0.0359,
      fixedAmountFee: 999,
      variableFee: null,
      minFinance: 5000,
      maxFinance: 2000000,
      minPropertyValue: 80000,
      location: 'ENGLAND_SCOTLAND_WALES'
    },
    {
      lender: 'HALIFAX',
      lenderName: 'Halifax',
      productName: 'RETENTION >¬£250k Intermediary 5 Year Fixed ¬£1499 Fee Product Transfer/Further Advance Large Loan',
      purpose: 'RETENTION',
      productType: 'RESIDENTIAL',
      repaymentType: 'REPAYMENT',
      lTV: 0.85,
      initialPeriodMonths: 60,
      initialPeriodLimit: '2025-10-31',
      initialRate: 0.0191,
      initialRateType: 'FIXED',
      minTerm: 84,
      maxTerm: 420,
      variableRate: 0.0359,
      fixedAmountFee: 1499,
      variableFee: null,
      minFinance: 0,
      maxFinance: 7500000,
      minPropertyValue: 80000,
      location: 'ENGLAND_SCOTLAND_WALES'
    },
    {
      lender: 'HALIFAX',
      lenderName: 'Halifax',
      productName: 'RETENTION >¬£250k Intermediary 5 Year Fixed No Fee Product Transfer/Further Advance Large Loan',
      purpose: 'RETENTION',
      productType: 'RESIDENTIAL',
      repaymentType: 'REPAYMENT',
      lTV: 0.85,
      initialPeriodMonths: 60,
      initialPeriodLimit: '2025-10-31',
      initialRate: 0.02,
      initialRateType: 'FIXED',
      minTerm: 84,
      maxTerm: 420,
      variableRate: 0.0359,
      fixedAmountFee: 0,
      variableFee: null,
      minFinance: 0,
      maxFinance: 7500000,
      minPropertyValue: 80000,
      location: 'ENGLAND_SCOTLAND_WALES'
    },
    {
      lender: 'VIRGIN_MONEY',
      lenderName: 'Virgin Money',
      productName: 'RETENTION HTB 5 Year Fixed No Fee',
      purpose: 'RETENTION',
      productType: 'RESIDENTIAL',
      repaymentType: 'REPAYMENT',
      lTV: 0.85,
      initialPeriodMonths: 60,
      initialPeriodLimit: '2025-09-01',
      initialRate: 0.0309,
      initialRateType: 'FIXED',
      minTerm: 84,
      maxTerm: 420,
      variableRate: 0.0434,
      fixedAmountFee: 0,
      variableFee: null,
      minFinance: 0,
      maxFinance: 450000,
      minPropertyValue: 80000,
      location: 'ENGLAND_SCOTLAND_WALES'
    },
    {
      lender: 'NATIONWIDE',
      lenderName: 'Nationwide',
      productName: '5 Year Fixed HTB Equity Loan ¬£999 Fee Remortgage',
      purpose: 'REMORTGAGE',
      productType: 'RESIDENTIAL',
      repaymentType: 'REPAYMENT',
      lTV: 0.85,
      initialPeriodMonths: 60,
      initialPeriodLimit: null,
      initialRate: 0.0194,
      initialRateType: 'FIXED',
      minTerm: 84,
      maxTerm: 420,
      variableRate: 0.0359,
      fixedAmountFee: 999,
      variableFee: null,
      minFinance: 25000,
      maxFinance: 750000,
      minPropertyValue: 80000,
      location: 'ENGLAND_SCOTLAND_WALES'
    },
    {
      lender: 'BARCLAYS',
      lenderName: 'Barclays',
      productName: 'RETENTION 5 Year Fixed ¬£999 Fee EMC Reward Rate Switch Only',
      purpose: 'RETENTION',
      productType: 'RESIDENTIAL',
      repaymentType: 'REPAYMENT',
      lTV: 0.8,
      initialPeriodMonths: 60,
      initialPeriodLimit: '2025-07-31',
      initialRate: 0.0177,
      initialRateType: 'FIXED',
      minTerm: 84,
      maxTerm: 420,
      variableRate: 0.0359,
      fixedAmountFee: 999,
      variableFee: null,
      minFinance: 5000,
      maxFinance: 2000000,
      minPropertyValue: 80000,
      location: 'ENGLAND_SCOTLAND_WALES'
    },
    {
      lender: 'HALIFAX',
      lenderName: 'Halifax',
      productName: 'RETENTION >¬£250k Intermediary 5 Year Fixed ¬£1499 Fee Product Transfer/Further Advance Large Loan',
      purpose: 'RETENTION',
      productType: 'RESIDENTIAL',
      repaymentType: 'REPAYMENT',
      lTV: 0.8,
      initialPeriodMonths: 60,
      initialPeriodLimit: '2025-10-31',
      initialRate: 0.0191,
      initialRateType: 'FIXED',
      minTerm: 84,
      maxTerm: 420,
      variableRate: 0.0359,
      fixedAmountFee: 1499,
      variableFee: null,
      minFinance: 0,
      maxFinance: 7500000,
      minPropertyValue: 80000,
      location: 'ENGLAND_SCOTLAND_WALES'
    },
    {
      lender: 'HALIFAX',
      lenderName: 'Halifax',
      productName: 'RETENTION >¬£250k Intermediary 5 Year Fixed No Fee Product Transfer/Further Advance Large Loan',
      purpose: 'RETENTION',
      productType: 'RESIDENTIAL',
      repaymentType: 'REPAYMENT',
      lTV: 0.8,
      initialPeriodMonths: 60,
      initialPeriodLimit: '2025-10-31',
      initialRate: 0.02,
      initialRateType: 'FIXED',
      minTerm: 84,
      maxTerm: 420,
      variableRate: 0.0359,
      fixedAmountFee: 0,
      variableFee: null,
      minFinance: 0,
      maxFinance: 7500000,
      minPropertyValue: 80000,
      location: 'ENGLAND_SCOTLAND_WALES'
    },
    {
      lender: 'VIRGIN_MONEY',
      lenderName: 'Virgin Money',
      productName: 'RETENTION HTB 5 Year Fixed ¬£995 Fee',
      purpose: 'RETENTION',
      productType: 'RESIDENTIAL',
      repaymentType: 'REPAYMENT',
      lTV: 0.8,
      initialPeriodMonths: 60,
      initialPeriodLimit: '2025-09-01',
      initialRate: 0.0259,
      initialRateType: 'FIXED',
      minTerm: 84,
      maxTerm: 420,
      variableRate: 0.0434,
      fixedAmountFee: 995,
      variableFee: null,
      minFinance: 0,
      maxFinance: 450000,
      minPropertyValue: 80000,
      location: 'ENGLAND_SCOTLAND_WALES'
    },
    {
      lender: 'BARCLAYS',
      lenderName: 'Barclays',
      productName: '5 Year Fixed ¬£999 Fee Remortgage',
      purpose: 'REMORTGAGE',
      productType: 'RESIDENTIAL',
      repaymentType: 'REPAYMENT',
      lTV: 0.8,
      initialPeriodMonths: 60,
      initialPeriodLimit: '2025-07-31',
      initialRate: 0.0177,
      initialRateType: 'FIXED',
      minTerm: 84,
      maxTerm: 420,
      variableRate: 0.0359,
      fixedAmountFee: 999,
      variableFee: null,
      minFinance: 5000,
      maxFinance: 2000000,
      minPropertyValue: 80000,
      location: 'ENGLAND_SCOTLAND_WALES'
    },
    {
      lender: 'HALIFAX',
      lenderName: 'Halifax',
      productName: 'RETENTION >¬£250k Intermediary 5 Year Fixed ¬£1499 Fee Product Transfer/Further Advance Large Loan',
      purpose: 'RETENTION',
      productType: 'RESIDENTIAL',
      repaymentType: 'REPAYMENT',
      lTV: 0.75,
      initialPeriodMonths: 60,
      initialPeriodLimit: '2025-10-31',
      initialRate: 0.0154,
      initialRateType: 'FIXED',
      minTerm: 84,
      maxTerm: 420,
      variableRate: 0.0359,
      fixedAmountFee: 1499,
      variableFee: null,
      minFinance: 0,
      maxFinance: 7500000,
      minPropertyValue: 80000,
      location: 'ENGLAND_SCOTLAND_WALES'
    },
    {
      lender: 'HALIFAX',
      lenderName: 'Halifax',
      productName: 'RETENTION >¬£250k Intermediary 5 Year Fixed No Fee Product Transfer/Further Advance Large Loan',
      purpose: 'RETENTION',
      productType: 'RESIDENTIAL',
      repaymentType: 'REPAYMENT',
      lTV: 0.75,
      initialPeriodMonths: 60,
      initialPeriodLimit: '2025-10-31',
      initialRate: 0.0163,
      initialRateType: 'FIXED',
      minTerm: 84,
      maxTerm: 420,
      variableRate: 0.0359,
      fixedAmountFee: 0,
      variableFee: null,
      minFinance: 0,
      maxFinance: 7500000,
      minPropertyValue: 80000,
      location: 'ENGLAND_SCOTLAND_WALES'
    },
    {
      lender: 'BARCLAYS',
      lenderName: 'Barclays',
      productName: 'RETENTION 5 Year Fixed No Fee EMC Reward',
      purpose: 'RETENTION',
      productType: 'RESIDENTIAL',
      repaymentType: 'REPAYMENT',
      lTV: 0.75,
      initialPeriodMonths: 60,
      initialPeriodLimit: '2025-07-31',
      initialRate: 0.0185,
      initialRateType: 'FIXED',
      minTerm: 84,
      maxTerm: 420,
      variableRate: 0.0359,
      fixedAmountFee: 0,
      variableFee: null,
      minFinance: 5000,
      maxFinance: 2000000,
      minPropertyValue: 80000,
      location: 'ENGLAND_SCOTLAND_WALES'
    },
    {
      lender: 'VIRGIN_MONEY',
      lenderName: 'Virgin Money',
      productName: 'RETENTION HTB 5 Year Fixed ¬£995 Fee',
      purpose: 'RETENTION',
      productType: 'RESIDENTIAL',
      repaymentType: 'REPAYMENT',
      lTV: 0.75,
      initialPeriodMonths: 60,
      initialPeriodLimit: '2025-09-01',
      initialRate: 0.0185,
      initialRateType: 'FIXED',
      minTerm: 84,
      maxTerm: 420,
      variableRate: 0.0434,
      fixedAmountFee: 995,
      variableFee: null,
      minFinance: 0,
      maxFinance: 450000,
      minPropertyValue: 80000,
      location: 'ENGLAND_SCOTLAND_WALES'
    },
    {
      lender: 'BARCLAYS',
      lenderName: 'Barclays',
      productName: '5 Year Fixed ¬£999 Fee Remortgage ¬£250 Cashback',
      purpose: 'REMORTGAGE',
      productType: 'RESIDENTIAL',
      repaymentType: 'REPAYMENT',
      lTV: 0.75,
      initialPeriodMonths: 60,
      initialPeriodLimit: '2025-07-31',
      initialRate: 0.0155,
      initialRateType: 'FIXED',
      minTerm: 84,
      maxTerm: 420,
      variableRate: 0.0359,
      fixedAmountFee: 999,
      variableFee: null,
      minFinance: 5000,
      maxFinance: 2000000,
      minPropertyValue: 80000,
      location: 'ENGLAND_SCOTLAND_WALES'
    },
    {
      lender: 'HALIFAX',
      lenderName: 'Halifax',
      productName: 'RETENTION >¬£250k Intermediary 5 Year Fixed ¬£1499 Fee Product Transfer/Further Advance Large Loan',
      purpose: 'RETENTION',
      productType: 'RESIDENTIAL',
      repaymentType: 'REPAYMENT',
      lTV: 0.6,
      initialPeriodMonths: 60,
      initialPeriodLimit: '2025-10-31',
      initialRate: 0.0141,
      initialRateType: 'FIXED',
      minTerm: 84,
      maxTerm: 420,
      variableRate: 0.0359,
      fixedAmountFee: 1499,
      variableFee: null,
      minFinance: 0,
      maxFinance: 7500000,
      minPropertyValue: 80000,
      location: 'ENGLAND_SCOTLAND_WALES'
    },
    {
      lender: 'HALIFAX',
      lenderName: 'Halifax',
      productName: 'RETENTION >¬£250k Intermediary 5 Year Fixed No Fee Product Transfer/Further Advance Large Loan',
      purpose: 'RETENTION',
      productType: 'RESIDENTIAL',
      repaymentType: 'REPAYMENT',
      lTV: 0.6,
      initialPeriodMonths: 60,
      initialPeriodLimit: '2025-10-31',
      initialRate: 0.015,
      initialRateType: 'FIXED',
      minTerm: 84,
      maxTerm: 420,
      variableRate: 0.0359,
      fixedAmountFee: 0,
      variableFee: null,
      minFinance: 0,
      maxFinance: 7500000,
      minPropertyValue: 80000,
      location: 'ENGLAND_SCOTLAND_WALES'
    },
    {
      lender: 'BARCLAYS',
      lenderName: 'Barclays',
      productName: 'RETENTION 5 Year Fixed ¬£999 Fee EMC Reward',
      purpose: 'RETENTION',
      productType: 'RESIDENTIAL',
      repaymentType: 'REPAYMENT',
      lTV: 0.6,
      initialPeriodMonths: 60,
      initialPeriodLimit: '2025-07-31',
      initialRate: 0.0145,
      initialRateType: 'FIXED',
      minTerm: 84,
      maxTerm: 420,
      variableRate: 0.0359,
      fixedAmountFee: 999,
      variableFee: null,
      minFinance: 5000,
      maxFinance: 2000000,
      minPropertyValue: 80000,
      location: 'ENGLAND_SCOTLAND_WALES'
    },
    {
      lender: 'BARCLAYS',
      lenderName: 'Barclays',
      productName: '5 Year Fixed ¬£999 Fee Remortgage ¬£250 Cashback',
      purpose: 'REMORTGAGE',
      productType: 'RESIDENTIAL',
      repaymentType: 'REPAYMENT',
      lTV: 0.6,
      initialPeriodMonths: 60,
      initialPeriodLimit: '2025-07-31',
      initialRate: 0.0145,
      initialRateType: 'FIXED',
      minTerm: 84,
      maxTerm: 420,
      variableRate: 0.0359,
      fixedAmountFee: 999,
      variableFee: null,
      minFinance: 5000,
      maxFinance: 2000000,
      minPropertyValue: 80000,
      location: 'ENGLAND_SCOTLAND_WALES'
    }
  ],
  lenderSVR: [
    {
      lenderName: 'Select lender',
      lender: '',
    },
    {
      lenderName: 'Accord Mortgages',
      lender: 'ACCORD_MORTGAGES',
      rate: 0.0425
    },
    {
      lenderName: 'Aldermore Bank',
      lender: 'ALDERMORE_BANK',
      rate: 0.0533
    },
    {
      lenderName: 'Bank of Ireland UK',
      lender: 'BANK_OF_IRELAND_UK',
      rate: 0.0409
    },
    {
      lenderName: 'Barclays',
      lender: 'BARCLAYS',
      rate: 0.0359
    },
    {
      lenderName: 'Bath Building Society',
      lender: 'BATH_BUILDING_SOCIETY',
      rate: 0.049
    },
    {
      lenderName: 'Beverley Building Society',
      lender: 'BEVERLEY_BUILDING_SOCIETY',
      rate: 0.0499
    },
    {
      lenderName: 'Buckinghamshire Building Society',
      lender: 'BUCKINGHAMSHIRE_BUILDING_SOCIETY',
      rate: 0.0474
    },
    {
      lenderName: 'Cambridge Building Society',
      lender: 'CAMBRIDGE_BUILDING_SOCIETY',
      rate: 0.0489
    },
    {
      lenderName: 'Chelsea Building Society',
      lender: 'CHELSEA_BUILDING_SOCIETY',
      rate: 0.0425
    },
    {
      lenderName: 'Chorley & District Building Society',
      lender: 'CHORLEY_&_DISTRICT_BUILDING_SOCIETY',
      rate: 0.0574
    },
    {
      lenderName: 'Clydesdale Bank',
      lender: 'CLYDESDALE_BANK',
      rate: 0.0455
    },
    {
      lenderName: 'Coventry Building Society',
      lender: 'COVENTRY_BUILDING_SOCIETY',
      rate: 0.0449
    },
    {
      lenderName: 'Cumberland',
      lender: 'CUMBERLAND',
      rate: 0.0474
    },
    {
      lenderName: 'Cynergy Bank',
      lender: 'CYNERGY_BANK',
      rate: 0.0475
    },
    {
      lenderName: 'Danske Bank',
      lender: 'DANSKE_BANK',
      rate: 0.033
    },
    {
      lenderName: 'Darlington Building Society',
      lender: 'DARLINGTON_BUILDING_SOCIETY',
      rate: 0.053
    },
    {
      lenderName: 'Earl Shilton Building Society',
      lender: 'EARL_SHILTON_BUILDING_SOCIETY',
      rate: 0.0524
    },
    {
      lenderName: 'Family Building Society',
      lender: 'FAMILY_BUILDING_SOCIETY',
      rate: 0.0439
    },
    {
      lenderName: 'First Direct',
      lender: 'FIRST_DIRECT',
      rate: 0.0419
    },
    {
      lenderName: 'Foundation Home Loans',
      lender: 'FOUNDATION_HOME_LOANS',
      rate: 0.0509
    },
    {
      lenderName: 'Furness Building Society',
      lender: 'FURNESS_BUILDING_SOCIETY',
      rate: 0.0514
    },
    {
      lenderName: 'Halifax',
      lender: 'HALIFAX',
      rate: 0.0359
    },
    {
      lenderName: 'Hanley Economic Building Society',
      lender: 'HANLEY_ECONOMIC_BUILDING_SOCIETY',
      rate: 0.0479
    },
    {
      lenderName: 'Harpenden Building Society',
      lender: 'HARPENDEN_BUILDING_SOCIETY',
      rate: 0.0419
    },
    {
      lenderName: 'Hinckley and Rugby Building Society',
      lender: 'HINCKLEY_AND_RUGBY_BUILDING_SOCIETY',
      rate: 0.0589
    },
    {
      lenderName: 'HSBC',
      lender: 'HSBC',
      rate: 0.0354
    },
    {
      lenderName: 'Kensington Mortgages',
      lender: 'KENSINGTON_MORTGAGES',
      rate: 0.051
    },
    {
      lenderName: 'Kent Reliance',
      lender: 'KENT_RELIANCE',
      rate: 0.045
    },
    {
      lenderName: 'Leeds Building Society',
      lender: 'LEEDS_BUILDING_SOCIETY',
      rate: 0.0444
    },
    {
      lenderName: 'Lloyds Bank',
      lender: 'LLOYDS_BANK',
      rate: 0.0359
    },
    {
      lenderName: 'Loughborough Building Society',
      lender: 'LOUGHBOROUGH_BUILDING_SOCIETY',
      rate: 0.0534
    },
    {
      lenderName: 'Market Harborough Building Society',
      lender: 'MARKET_HARBOROUGH_BUILDING_SOCIETY',
      rate: 0.0549
    },
    {
      lenderName: 'Marsden Building Society',
      lender: 'MARSDEN_BUILDING_SOCIETY',
      rate: 0.057
    },
    {
      lenderName: 'Masthaven',
      lender: 'MASTHAVEN',
      rate: 0.045
    },
    {
      lenderName: 'MBS Lending',
      lender: 'MBS_LENDING',
      rate: 0.06
    },
    {
      lenderName: 'Metro Bank',
      lender: 'METRO_BANK',
      rate: 0.036
    },
    {
      lenderName: 'Monmouthshire Building Society',
      lender: 'MONMOUTHSHIRE_BUILDING_SOCIETY',
      rate: 0.0474
    },
    {
      lenderName: 'Nationwide',
      lender: 'NATIONWIDE',
      rate: 0.0359
    },
    {
      lenderName: 'NatWest',
      lender: 'NATWEST',
      rate: 0.0359
    },
    {
      lenderName: 'Newbury Building Society',
      lender: 'NEWBURY_BUILDING_SOCIETY',
      rate: 0.0395
    },
    {
      lenderName: 'Newcastle Building Society',
      lender: 'NEWCASTLE_BUILDING_SOCIETY',
      rate: 0.0599
    },
    {
      lenderName: 'Nottingham Building Society',
      lender: 'NOTTINGHAM_BUILDING_SOCIETY',
      rate: 0.0574
    },
    {
      lenderName: 'Paragon',
      lender: 'PARAGON',
      rate: 0.0459
    },
    {
      lenderName: 'Penrith Building Society',
      lender: 'PENRITH_BUILDING_SOCIETY',
      rate: 0.0475
    },
    {
      lenderName: 'Pepper',
      lender: 'PEPPER',
      rate: 0.062
    },
    {
      lenderName: 'Platform',
      lender: 'PLATFORM',
      rate: 0.0434
    },
    {
      lenderName: 'Post Office',
      lender: 'POST_OFFICE',
      rate: 0.0409
    },
    {
      lenderName: 'Precise Mortgages',
      lender: 'PRECISE_MORTGAGES',
      rate: 0.0485
    },
    {
      lenderName: 'Principality Building Society',
      lender: 'PRINCIPALITY_BUILDING_SOCIETY',
      rate: 0.0505
    },
    {
      lenderName: 'Progressive Building Society',
      lender: 'PROGRESSIVE_BUILDING_SOCIETY',
      rate: 0.0435
    },
    {
      lenderName: 'RBS',
      lender: 'RBS',
      rate: 0.0359
    },
    {
      lenderName: 'Saffron Building Society',
      lender: 'SAFFRON_BUILDING_SOCIETY',
      rate: 0.0539
    },
    {
      lenderName: 'Santander',
      lender: 'SANTANDER',
      rate: 0.0335
    },
    {
      lenderName: 'Scottish Building Society',
      lender: 'SCOTTISH_BUILDING_SOCIETY',
      rate: 0.0529
    },
    {
      lenderName: 'Scottish Widows',
      lender: 'SCOTTISH_WIDOWS',
      rate: 0.0424
    },
    {
      lenderName: 'Skipton Building Society',
      lender: 'SKIPTON_BUILDING_SOCIETY',
      rate: 0.0399
    },
    {
      lenderName: 'Stafford Railway Building Society',
      lender: 'STAFFORD_RAILWAY_BUILDING_SOCIETY',
      rate: 0.0345
    },
    {
      lenderName: 'Swansea Building Society',
      lender: 'SWANSEA_BUILDING_SOCIETY',
      rate: 0.046
    },
    {
      lenderName: 'Teachers Building Society',
      lender: 'TEACHERS_BUILDING_SOCIETY',
      rate: 0.0524
    },
    {
      lenderName: 'Tipton & Coseley Building Society',
      lender: 'TIPTON_&_COSELEY_BUILDING_SOCIETY',
      rate: 0.0524
    },
    {
      lenderName: 'TSB',
      lender: 'TSB',
      rate: 0.0359
    },
    {
      lenderName: 'United Trust Bank',
      lender: 'UNITED_TRUST_BANK',
      rate: 0.0475
    },
    {
      lenderName: 'Vida Homeloans',
      lender: 'VIDA_HOMELOANS',
      rate: 0.0594
    },
    {
      lenderName: 'Virgin Money',
      lender: 'VIRGIN_MONEY',
      rate: 0.0434
    },
    {
      lenderName: 'West Bromwich Building Society',
      lender: 'WEST_BROMWICH_BUILDING_SOCIETY',
      rate: 0.0399
    },
    {
      lenderName: 'Yorkshire Building Society',
      lender: 'YORKSHIRE_BUILDING_SOCIETY',
      rate: 0.0425
    }
  ],
  // inputs: {
  //   purchaseDateMonth: 7,
  //   purchaseDateYear: 2015,
  //   purchasePrice: 400000,
  //   equityPercentage: 20,
  //   currentValue: 450000,
  //   currentLender: 'BARCLAYS',
  //   outstandingBalance: 270000,
  //   initialRateEndDateMonth: 7,
  //   initialRateEndDateYear: 2020,
  //   currentRate: 2,
  //   assumedHousePrice: 0,
  //   assumedRPI: 3
  // }
  inputs: {
    purchaseDateMonth: '',
    purchaseDateYear: '',
    purchasePrice: undefined,
    equityPercentage: undefined,
    currentValue: undefined,
    currentLender: '',
    outstandingBalance: undefined,
    initialRateEndDateMonth: '',
    initialRateEndDateYear: undefined,
    currentRate: undefined,
    assumedHousePrice: 0,
    assumedRPI: 3
  }
};
