import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormContext } from '../MultiStepForm';

import Input from '../../decorators/withInput';
import {
  inputsTypes, inputsDefaultTypes
} from '../../types';

const Step4 = ({ handleChangeInputCurrency, inputs }) => {
  const { setErrorForm } = useContext(FormContext);

  useEffect(() => {
    setErrorForm(false);
  }, []);

  useEffect(() => {
    if (inputs.currentValue === undefined) {
      setErrorForm(true);
    } else {
      setErrorForm(false);
    }
  }, [inputs.currentValue]);

  return (
    <article className="steps">
      <h1 className="steps-title">What is your estimate of the current value of your home?</h1>
      <p className="steps-subtitle">You can use <a href="https://www.zoopla.co.uk/" target="_blank" rel="noopener noreferrer">Zoopla</a> to estimate if you’re not sure</p>
      <div className="steps-form">
        <Input
          name="currentValue"
          type="tel"
          format="currency"
          required
          onChange={handleChangeInputCurrency}
          value={inputs.currentValue}
        />
      </div>
    </article>
  );
};

Step4.propTypes = {
  inputs: inputsTypes,
  handleChangeInputCurrency: PropTypes.func
};


Step4.defaultProps = {
  inputs: inputsDefaultTypes,
  handleChangeInputCurrency: () => {},
};

const mapStateToProps = (state) => ({
  inputs: state.inputs,
});

export default connect(
  mapStateToProps
)(Step4);
