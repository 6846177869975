import React from 'react';
/* eslint-disable */

const PrivacyPolicy = () => (
  <>
    <h1 className="legal-title">Privacy Policy</h1>
    <ol className="legal-index">
      <li><a href="#introduction">Introduction</a></li>
      <li><a href="#personal-data">Types of personal data we collect from you and its use</a></li>
      <li><a href="#ip-address">IP Address</a></li>
      <li><a href="#cookies">Use of Cookies</a></li>
      <li><a href="#use-information">How we use your information</a></li>
      <li><a href="#legal">The Legal basis and purposes using your personal data</a></li>
      <li><a href="#share">Who we share your personal data with</a></li>
      <li><a href="#store">How we store, process and secure your personal data</a></li>
      <li><a href="#retention">Data retention policy</a></li>
      <li><a href="#rights">Your rights</a></li>
    </ol>
    <ol className="legal-list">
      <li id="introduction">
        <h3 className="title-xxl legal-list-title">Introduction</h3>
        <p>Anji is the trading name of Seven Summits Financial Limited (registered company number 09981525). We are Anji, the ‘data controller’ and we’re responsible for deciding how we can use your information.</p>
        <p>This privacy policy (together with any other documents referred to on it) sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. Your personal data is data which by itself or with other data available to us can be used to identify you.</p>
        <p>Where there are two or more people named, this privacy policy applies to each person separately.</p>
        <p>Please read the following carefully and If you have any questions, please contact us using the contact details provided below.</p>
      </li>
      <li id="personal-data">
        <h3 className="title-xxl legal-list-title">Types of personal data we collect from you and its use</h3>
        <p>We will need to ask you for certain personal information to give you the best possible experience when you engage with us (via our websites or otherwise) and when you use our products and services.</p>
        <p>Please see below what types of personal information we will ask for or collect. This section includes information on cookies.</p>
        <p><strong>Contact Information</strong><br />When you utilise Anji's Services we will ask you to provide some contact information. Contact information may include some or all of the following:</p>
        <ul className="legal-list-ul">
          <li>Full Name.</li>
          <li>Residential Address.</li>
          <li>Previous Residential Address(es).</li>
          <li>Landline Phone Number.</li>
          <li>Mobile Phone Number.</li>
          <li>Email Address.</li>
        </ul>
        <p>We will only retain your Contact information for as long as there is a continuing need to retain it.</p>
        <p><strong>Device and Interaction Information</strong><br />We (Ourselves, via the use of cookies, and via third parties including <a href="https://www.adobe.com/uk/privacy/policy.html" target="_blank">Adobe Analytics</a> and <a href="https://policies.google.com/privacy?hl=en-GB&gl=uk;" target="_blank" rel="noopener noreferrer">Google Analytics</a>) may collect certain data automatically about your visit to our website, or use of our Services. This may include but is not limited to the following data:</p>
        <ul className="legal-list-ul">
          <li>How you connect to the internet and how you engage with our site.</li>
          <li>Browser data stored on your device (such as cookies, see further detail below).</li>
          <li>Location data determined using an anonymised version of your IP address, or location information from your mobile device or web browser.</li>
          <li>Information about your web browser such as browser type, browser language, advertising identifier.</li>
          <li>Information about your device such as device type, operating system, connection speed, internet service provider, and display settings (such as 
          screen size and resolution).</li>
          <li>Your web browsing activity on our services, including the URLs and names of the web pages you visit on our site, time of day of your visit, time spent on each page, and what you do on them including whether you clicked on link, image or text on our site or downloaded a file, image, etc.</li>
          <li>The URL of the page that displayed the link that you clicked on, which brought you to our website and the web search you performed that led you  to our website.</li>
          <li>Social network profile information.</li>
          <li>Information you may provide when interacting with that company’s social media pages, such as information you provide on forms.</li>
        </ul>
        <p>We do not use any of this data to identify you, it is stored anonymously and used to improve our services and track sales.</p>
        <p>We will only retain your device and interaction information for as long as there is a continuing need to retain it.</p>
      </li>
      <li id="ip-address">
        <h3 className="title-xxl legal-list-title">IP Address</h3>
        <p>We will process your IP address in order to allow us to identify legitimate clicks across our services. We need to do this to allow our business to function and so that we can provide the service to you. They are never used to identify you.</p>
        <p>We will only retain your IP address for as long as there is a continuing need to retain it.</p>
        <p><strong>Sales Accreditation Data</strong><br />As part of the comparison service, we may collect data from the provider or broker from whom you take out a product via our website. This data may include the status and outcome of your application and details of the product you took out.</p>
        <p>We will only retain your Sales Accreditation Data for as long as there is a continuing need to retain it.</p>
        <p><strong>Application and Server Logs</strong><br />We store logs of activity on our applications, this isn’t tied to a user but does include the IP address that activity originated from. We use this information to identify and fix issues and to identify and prevent fraud and security issues.</p>
        <p>We will only retain logging information for 30 days.</p>
        <p><strong>Other</strong><br />Finally, we also collect, or you provide, in certain services and/or circumstances:</p>
        <ul className="legal-list-ul">
          <li>Time at address.</li>
          <li>Salary/Income.</li>
          <li>Employment status.</li>
          <li>Employment duration.</li>
          <li>Investment property details.</li>
          <li>Credit commitments.</li>
          <li>Savings.</li>
        </ul>
      </li>
      <li id="cookies">
        <h3 className="title-xxl legal-list-title">Use of Cookies</h3>
        <p>We will only retain your Other information for as long as there is a continuing need to retain it.<br />This website may use cookies to collect information about you. If you use this website, then you accept that we can use the cookies set out below.</p>
        <p><strong>What are cookies?</strong><br />Cookies are small data files which are placed on your computer by this website and which collect certain personal information about you.</p>
        <p><strong>Why do we use cookies?</strong><br />Cookies enable us to tailor our service offering (including the website) and to provide you with a better service.</p>
        <p>We use the following cookies:</p>
        <ul className="legal-list-ul">
          <li><strong>Strictly necessary cookies.</strong> These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website.</li>
          <li><strong>Analytical/performance cookies.</strong> They allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.</li>
          <li><strong>Functionality cookies.</strong> These are used to recognise you when you return to our website. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).</li>
          <li><strong>Targeting cookies.</strong> These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.</li>
        </ul>
        <p>Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies. You block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our site.</p>
      </li>
      <li id="use-information">
        <h3 className="title-xxl legal-list-title">How we use your information</h3>
        <p>We use your personal information in lots of ways to make our products and services as effective as possible.</p>
        <p>Please see below what we will use your personal information for:</p>
        <p><strong>To enable you to access our website and use our services</strong> - We will use your information to accept you as a new/returning customer and continue to provide you with our products and services.</p>
        <p><strong>To provide and improve customer support</strong> - We will use your information to be able to provide and improve the customer support we provide to you.</p>
        <p><strong>For internal training purposes</strong> - We will use your information to ensure that our team has the knowledge and expertise to ensure we provide the best possible experience to you when you interact with us.</p>
        <p><strong>For fraud investigation</strong> - We will use your information for fraud investigation in relation to any requests made by government agencies. We will disclose any information we have in our possession to law enforcement or government officials in response to any inquiry or investigation or if in our sole discretion, we believe that it is necessary or appropriate in connection with any investigation or activity that is illegal.</p>
        <p><strong>For reporting and analytical purposes</strong> - We will use your information for reporting and analytical purposes to enable us to improve our products and services and provide appropriate levels of support to our customers.</p>
        <p><strong>For market research</strong> - We may select particular customers and invite them to be involved in market research. If you accept this invitation, we will use the feedback you give us to improve our products and services.</p>
        <p><strong>To track activity on our website</strong> - We will use your information to track your activity on our websites to help us better understand your interests and how you interact with us. We will also use this information to better engage with you and to ensure that you get the best service we can provide and improve the products in the future.</p>
        <p><strong>To maintain our records and other administrative purposes</strong> - We will use your information to ensure that we maintain comprehensive and up to date records of the ways we process your personal information and other operational activities and therefore we will use the information you provide for record-keeping, updates and general administrative purposes.</p>
        <p><strong>For complaint and dispute resolution</strong> - Whilst we will try to make sure that you are happy with the service we provide and do not feel the need to complain, if you do complain to us, we will use the information we have about you to help us manage your complaint and to bring it to a close.</p>
        <p><strong>To comply with the law</strong> - Like any other business, we are required to comply with many laws and regulations. We will, where necessary, use your information to the extent required to enable us to comply with these requirements</p>
        <p><strong>For Broker enquiries</strong> - To enquire about or apply for certain mortgages on our comparison sites you may need to talk to a broker; If this is the case, we will ask for your permission to send your name and contact details to them. Your details will only be sent to the broker of your chosen product.</p>
        <p>If we use your personal information for any purposes that are not set out in this Privacy Policy, we promise to let you know exactly what we will use it for before we go ahead and use it.</p>
      </li>
      <li id="legal">
        <h3 className="title-xxl legal-list-title">The Legal basis and purposes using your personal data</h3>
        <p>Contract: We will process your personal data as needed to perform our contract with you for the relevant product and service. This is in order to take steps at your request before entering into it, decide whether to enter into it, manage and perform that contract, update our records and contact you about your account and recovering debt.</p>
        <p>Legitimate interests: We will process your personal data as needed for our own legitimate business interests or those of other persons and organisations. For e.g. to ensure that content from our site is presented in the most effective manner for you, search at credit reference agencies if you’re above 18, monitoring all forms of communications in our dealings with you and activities in your account, market research and analysis and send marketing communications, helping to prevent and detect crime such as fraud and money laundering.</p>
        <p>Legal Obligations: We will process your personal data as needed to comply with legal obligations. This could be for compliance with legal and regulatory requirements and related disclosures, when you exercise your rights under data protection law and make requests, establishment and defence of legal rights, activities in relation to prevention, detention and investigation of crime, to verify ID and perform credit, fraud and anti-money laundering checks and monitoring all forms of communications in our dealings with you and activities in your account.</p>
        <p>Please see below to find out more about which of the above legal grounds we rely on when processing your information:</p>
        <ul className="legal-list-ul">
          <li>To enable you to access our website and use our services we rely on the legal basis of performance of a contract.</li>
          <li>To provide and improve customer support we rely on legitimate interests to ensure we provide the best possible experience to you when you interact with us.</li>
          <li>For internal training purposes, we rely on the legal basis of legitimate interests to ensure we provide the best possible experience to you when you interact with us.</li>
          <li>For fraud investigation, we rely on the legal basis of legal obligation.</li>
          <li>For reporting and analytical purposes, we rely on the legal basis of legitimate interests to improve our products and services and provide appropriate levels of support to our customers.</li>
          <li>For market research, we rely on the legal basis of legitimate interests to improve our products and services for our customers.</li>
          <li>To track activity on our website we rely on the legal basis of legitimate interests to engage with you and to ensure that you get the best service we can provide.</li>
          <li>To maintain our records and other administrative purposes we rely on the legal basis of performance of a contract.</li>
          <li>For complaint and dispute resolution we rely on the legal basis of performance of a contract.</li>
          <li>To comply with the law, we rely on the legal basis of legal obligation.</li>
          <li>For enquiries, we rely on the legal basis of performance of a contract or we rely on the basis of legitimate interest to provide the service you have requested.</li>
        </ul>
      </li>
      <li id="share">
        <h3 className="title-xxl legal-list-title">Who we share your personal data with</h3>
        <p>Subject to applicable data protection law, we will share your personal data with third parties only in the ways that are described in this privacy policy. We do not sell your personal data to third parties.</p>
        <p>We may share your personal data with:</p>
        <ul className="legal-list-ul">
          <li>Any member of our group of companies, which means our subsidiaries, associated companies where we have shareholdings.</li>
          <li>Business partners, suppliers and sub-contractors for the performance of any contract we enter into with them or you.</li>
          <li>Companies and other persons providing service to us.</li>
          <li>Our legal and other professional advisors.</li>
          <li>In an emergency or otherwise to protect your vital interests.</li>
          <li>To protect the security or integrity of our business operations.</li>
          <li>To other parties connected to your account e.g. joint applicants.</li>
          <li>Market Analytics and search engine providers that assist us in the improvement and optimisation of our products and services.</li>
          <li>In the event that we sell or buy any business or assets, in which case we may disclose your personal data to the prospective seller or buyer of such   business or assets.</li>
          <li>If we or substantially all of our assets are acquired by a third party, in which case personal data held by it about its customers will be one of the transferred assets.</li>
          <li>If we are under a duty to disclose or share your personal data in order to comply with any legal and regulatory obligation, or in order to enforce or apply any agreements between you; or to protect the rights, property, or safety of us, our customers, or others.</li>
        </ul>
      </li>
      <li id="store">
        <h3 className="title-xxl legal-list-title">How we store, process and secure your personal data</h3>
        <p>The data that we collect from you may be transferred to, and stored at, a destination outside the European Economic Area ("EEA"). It may also be processed by staff operating outside the EEA who work for us or for one of our suppliers. By submitting your personal data, you agree to this transfer, storing or processing. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this privacy policy. All information you provide to us via email or our site is stored on our secure servers.</p>
        <p>Where we have given you (or where you have chosen) a password which enables you to access certain parts of our site, you are responsible for keeping this password confidential. We ask you not to share a password with anyone.</p>
        <p>We follow generally accepted standards to protect the personal data submitted to us, both during transmission and once we receive it. Unfortunately, the transmission of information via the internet or method of electronic storage is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee its absolute security. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.</p>
      </li>
      <li id="retention">
        <h3 className="title-xxl legal-list-title">Data retention policy</h3>
        <p>We will keep your personal data for as long as you have a relationship with us. After it ends we will keep it where may need it for our legitimate purposes e.g. to help us respond to queries or complaints, or for other reasons e.g. fighting fraud and financial crime, and responding to requests from regulators.</p>
      </li>
      <li id="rights">
        <h3 className="title-xxl legal-list-title">Your rights</h3>
        <p>You have a number of rights relating to your information e.g. to see what we hold, to ask us to share it with another party, ask us to update incorrect or incomplete details, to object to or restrict processing of it, to make a complaint etc.</p>
        <p>More specifically, your rights are as follows:</p>
        <ul className="legal-list-ul">
          <li>The right to be informed about our processing of your personal data.</li>
          <li>The right to have your personal data if it is inaccurate and to have incomplete personal data completed.</li>
          <li>The right to object to processing of your personal data.</li>
          <li>The right to restrict processing of your personal data.</li>
          <li>The right to have your personal data erased (the “right to be forgotten”).</li>
          <li>The right to request access to your personal data and information about how we process it.</li>
          <li>The right to move, copy or transfer your personal data (the “data portability”).</li>
        </ul>
        <p>You can correct, change or delete your information in your member account setting page. You can stop receiving our newsletters or marketing emails by following the unsubscribe instructions included in these emails or accessing the email preferences in your account settings page. Alternatively, or to access any of the other above rights, you can email us at hello@anjihomes.com or by contacting us by telephone or postal mail at the contact information listed below.</p>
        <p>You also have the right to complain to the Information Commissioners Office (www.ico.org.uk), the UK independent authority set up to uphold information rights in the public interest, promoting openness by public bodies and data privacy for individuals.</p>
        <p>You can contact them by:</p>
        <ul className="legal-list-ul">
          <li>Going to their website at <a href="https://ico.org.uk/" target="_blank">https://ico.org.uk/</a></li>
          <li>Phone on 0303 123 1113</li>
          <li>Post to Information Commissioner’s Office, Wycliffe House, Water Lane, Wilmslow, SK9 5AF.</li>
        </ul>
        <p>You may also have the option to register your complaint using the European Commission Online Dispute Resolution (ODR) platform. This is a web-based platform that is designed to help consumers who have bought goods or services online to deal with issues arising from their purchase.</p>
      </li>
    </ol>
  </>
);

export default PrivacyPolicy;
