export function currencyToInt(currency) {
  return parseInt(currency.replace(/[^0-9.]+/g, ''), 10) || undefined;
}

export function intToCurrency(number) {
  return number ? parseInt(number, 10).toLocaleString('en-GB') : undefined;
}

export function formatPercentage(number) {
  return `${parseFloat((number * 100).toFixed(2))}%`;
}

export function stringToInt(string) {
  return string ? parseInt(string, 10) : undefined;
}

export function stringToFloat(string) {
  return !parseFloat(string) || !Number(string) || string.endsWith('.') ? undefined : parseFloat(string);
}
