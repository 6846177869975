import Stream from 'streamjs';
import renderFormulaResults from './getFormulaResults';

export default function getFilteredProducts(products, inputs, lenderSVR, purpose) {
  const s = Stream(products)
    .filter((product) => product.purpose !== 'RETENTION'
      || product.lender === inputs.currentLender)
    .sorted('initialRate')
    .map((filteredProduct) => {
      const formulaResults = renderFormulaResults(inputs, lenderSVR, filteredProduct);
      const remortgageBalance = purpose === 'REMORTGAGE' ? formulaResults.remortgage.mortgageBalance : formulaResults.redeemRemortgage.mortgageBalance;
      return ({
        product: filteredProduct,
        remortgageBalance,
        ...formulaResults
      });
    })
    .toArray();

  return Stream(s)
    .filter((p) => (p.remortgageBalance / inputs.currentValue) <= p.product.lTV)
    .filter((p) => p.product.minFinance <= p.remortgageBalance
      && p.product.maxFinance >= p.remortgageBalance)
    .toArray()[0];
}
