import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../Layout';
import mainImg from '../../images/article1-full.jpg';

const Covid = () => (
  <Layout>
    <Helmet>
      <title>Why is a valuation needed to repay Help to Buy?</title>
      <meta name="description" content="Before you can repay or staircase your Help to Buy Equity Loan, you’ll need a valuation. Learn more about why and how to get one." />
    </Helmet>
    <article className="post">
      <img src={mainImg} alt="Valuation to repay help to buy" />
      <div className="container post-wrapper">
        <h1 className="title-main">Why is a valuation needed to repay Help to Buy?</h1>
        <div className="post-content">
          <p className="post-subtitle">Before you can repay or staircase your Help to Buy Equity Loan, you’ll need a valuation. Learn more about why and how to get one.</p>
          <p>The Help to Buy Equity Loan is used to boost peoples’ ability to purchase a home. Whilst technically it is a loan and is documented as such, in some ways it could also be thought of as the government owning a share in the property. That’s because it is an equity loan - we explore exactly what that means and how valuations are used to determine the repayment amount.</p>
          <p className="post-title">How do equity loans work?</p>
          <p>A standard loan comprises interest and principal payments. Normally it requires you make a monthly payment that goes towards the interest on the outstanding balance and repays part of the loan. The next month, the total payment will be the same, however this time a bit more of it will go to repayment and a bit less to interest. The payments are designed such that after the final month’s payment, there is no balance left outstanding.</p>
          <p>However equity loans work slightly differently. The main point is that your repayment amount (excluding the interest) is not the amount borrowed initially - rather it could be higher or lower based on the value of the property. It is designed to behave as though the lender actually owns a stake in your property - they benefit from increases in the value and lose from declines.</p>
          <p className="post-title">Calculating the repayment amount</p>
          <p>The repayment amount on a Help to Buy Equity Loan is essentially the percentage amount of the loan (usually 20% outside of London and 40% in London) multiplied by the current market value of the property. Of course the current market value could be higher or lower than the value for which the property was bought and therefore you need to get a valuation to determine this.</p>
          <p>There are certain requirements on the valuation (see below), but before that - one complexity arises regarding from whether the property is being sold or not: </p>
          <table className="post-table left">
            <tr>
              <th>Repay the Equity Loan without selling the property</th>
              <th>Repay the Equity Loan by selling the property</th>
            </tr>
            <tr>
              <td>The valuation report on its own determines the market value for repayment.</td>
              <td>The <strong>higher of</strong> the valuation report and the property sale price is used to determine the market value.</td>
            </tr>
          </table>
          <p className="post-title">What are the requirements for an acceptable valuation?</p>
          <p>In most cases Help to Buy borrowers are able to choose a surveyor to value their home. In a few cases however, for example if the building has cladding, you may have to discuss with Target and agree who will conduct the survey.</p>
          <p><a href="https://www.myfirsthome.org.uk/" target="_blank" rel="noopener noreferrer">Target</a> will only accept a valuation if it meets certain criteria. We highlight the most important ones:</p>
          <ol>
            <li>1. The valuation must be conducted by a firm registered with the Royal Institution of Chartered Surveyors (commonly abbreviated as RICS).</li>
            <li>2. They have to be independent - this typically means they can’t be associated with any estate agent and they can’t be related or known to you.</li>
            <li>3. In the valuation report they must have included the sale prices and details of 3 comparable properties (comparable means like-for-like and within a 2 mile radius).</li>
            <li>4. The valuer must inspect inside the property and write a full report. Valuations used for the mortgage will not be accepted.</li>
          </ol>
          <p className="post-title">What happens if house prices have fallen?</p>
          <p>If the market value of the property really has fallen, in other words if the valuation meets all the requirements and comes in lower than what you paid initially, then you could repay less than the amount you borrowed initially.</p>
          <p>To illustrate with an example, imagine the property was initially bought for £300,000 using a 20% Equity Loan, equating to £60,000. Now if you want to repay the Equity Loan and the market value is £250,000, the repayment amount will be 20% of only £250,000, equating to £50,000. The £10,000 difference does not have to be repaid. This is one of the benefits of equity loans in general - they protect you in case property values fall.</p>
        </div>
      </div>
    </article>
  </Layout>
);

export default Covid;
