import { combineReducers } from 'redux';
import products from './products';
import inputs from './inputs';
import lenderSVR from './lenderSVR';

const helpToBuyManagementApp = combineReducers({
  products,
  lenderSVR,
  inputs
});

export default helpToBuyManagementApp;
