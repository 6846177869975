import React from 'react';
import { Helmet } from 'react-helmet';
import Error404 from '../Error404';
import Layout from '../Layout';

const Error = () => (
  <Layout>
    <Helmet>
      <title>Anji - not found</title>
      <meta name="description" content="404" />
    </Helmet>
    <Error404 />
  </Layout>
);

export default Error;
