import PropTypes from 'prop-types';

export const lenderSVRTypes = PropTypes.shape({
  lender: PropTypes.string,
  rate: PropTypes.number
});

export const inputsTypes = PropTypes.shape({
  purchaseDateMonth: PropTypes.number,
  purchaseDateYear: PropTypes.number,
  purchasePrice: PropTypes.number,
  equityPercentage: PropTypes.number,
  currentValue: PropTypes.number,
  currentLender: PropTypes.string,
  outstandingBalance: PropTypes.number,
  initialRateEndDateMonth: PropTypes.number,
  initialRateEndDateYear: PropTypes.number,
  currentRate: PropTypes.number
});

export const inputsDefaultTypes = {
  purchaseDateMonth: 1,
  purchaseDateYear: undefined,
  purchasePrice: 0,
  equityPercentage: 0,
  currentValue: 0,
  currentLender: '',
  outstandingBalance: 0,
  initialRateEndDateMonth: 1,
  initialRateEndDateYear: undefined,
  currentRate: 0
};

export const productsTypes = PropTypes.shape({
  lender: PropTypes.string,
  lenderName: PropTypes.string,
  productName: PropTypes.string,
  purpose: PropTypes.string,
  productType: PropTypes.string,
  repaymentType: PropTypes.string,
  lTV: PropTypes.number,
  initialPeriodMonths: PropTypes.number,
  initialPeriodLimit: PropTypes.string,
  initialRate: PropTypes.number,
  initialRateType: PropTypes.string,
  minTerm: PropTypes.number,
  maxTerm: PropTypes.number,
  variableRate: PropTypes.number,
  fixedAmountFee: PropTypes.number,
  variableFee: PropTypes.number,
  minFinance: PropTypes.number,
  maxFinance: PropTypes.number,
  minPropertyValue: PropTypes.number,
  location: PropTypes.string,
});

export const productsDefaultTypes = {
  lender: null,
  lenderName: null,
  productName: null,
  purpose: null,
  productType: null,
  repaymentType: null,
  lTV: null,
  initialPeriodMonths: null,
  initialPeriodLimit: null,
  initialRate: null,
  initialRateType: null,
  minTerm: null,
  maxTerm: null,
  variableRate: null,
  fixedAmountFee: null,
  variableFee: null,
  minFinance: null,
  maxFinance: null,
  minPropertyValue: null,
  location: null,
};
