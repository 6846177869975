import React from 'react';
import { createStore, compose, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { render } from 'react-snapshot';
import ReactGA from 'react-ga';
import App from './App';
import helpToBuyManagementApp from './reducers';
import initialState from './initialState';

import './styles/index.scss';

ReactGA.initialize('UA-75292476-3');

const store = createStore(
  helpToBuyManagementApp,
  initialState,
  compose(
    applyMiddleware(reduxThunk),
    // eslint-disable-next-line no-underscore-dangle
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f
  )
);

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
