import React, {
  useEffect, useContext
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormContext } from '../MultiStepForm';
import Select from '../../decorators/withSelectMe';
import { monthsArray, validateDate } from '../../lib/dateUtils';
import {
  inputsTypes, inputsDefaultTypes
} from '../../types';

const Step1 = ({ handleChangeSelect, inputs }) => {
  const { setErrorForm } = useContext(FormContext);

  const yearsArray = [{
    label: 'Select year',
    value: '',
  }];
  const date = new Date();
  const currentYear = date.getFullYear();
  for (let i = 2013; i <= currentYear; i += 1) {
    yearsArray.push({
      value: i,
      label: i,
    });
  }

  useEffect(() => {
    if (validateDate(
      {month: inputs.purchaseDateMonth, year: inputs.purchaseDateYear},
      {month: 4, year: 2013},
      {month: date.getMonth() + 1, year: date.getFullYear()}
    )) {
      setErrorForm(false);
    } else {
      setErrorForm(true);
    }
  }, [inputs.purchaseDateMonth, inputs.purchaseDateYear]);

  return (
    <article className="steps">
      <h1 className="steps-title">When did you buy your Help to Buy home?</h1>
      <p className="steps-subtitle">For example, April 2017</p>
      <p className="hint">Date should be between April 2013 and today</p>
      <div className="steps-form row row-gutterTiny">
        <div className="col col-desktop7 col-mobile12">
          <div className="form-field">
            <Select
              options={monthsArray}
              value={inputs.purchaseDateMonth}
              onChange={(e) => handleChangeSelect(e, 'purchaseDateMonth')}
            />
          </div>
        </div>
        <div className="col col-desktop5 col-mobile12">
          <Select
            options={yearsArray}
            value={inputs.purchaseDateYear}
            onChange={(e) => handleChangeSelect(e, 'purchaseDateYear')}
          />
        </div>
      </div>
    </article>
  );
};

Step1.propTypes = {
  inputs: inputsTypes,
  handleChangeSelect: PropTypes.func,
};


Step1.defaultProps = {
  inputs: inputsDefaultTypes,
  handleChangeSelect: () => {},
};

const mapStateToProps = (state) => ({
  inputs: state.inputs,
});

export default connect(
  mapStateToProps
)(Step1);
