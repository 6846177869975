import React from 'react';
import { Link } from 'react-router-dom';

const MainArticle = () => (
  <article className="mainArticle">
    <div className="container">
      <div className="row row-gutterSmall">
        <div className="mainArticle-content col col-desktop6 col-tablet12">
          <h1 className="title-primary">Should I remortgage to pay off the Help to Buy Equity Loan?</h1>
          <div className="subtitle-main row-separationMedium">
            <p>We take a detailed look at remortgaging to pay off your Equity Loan - who it might be good for and how to go about doing it.</p>
          </div>
          <Link
            to="/remortgage-to-pay-off-help-to-buy"
            className="row-separationMedium"
          >
            Read More
          </Link>
        </div>
      </div>
    </div>
  </article>
);

export default MainArticle;
