import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../Layout';
import about from '../../images/about.jpg';
import picuscapital from '../../images/picuscapital.png';

const About = () => (
  <Layout>
    <Helmet>
      <title>Anji - about us</title>
      <meta name="description" content="A diverse group of designers, developers and finance professionals with a mission to transform how people go about buying a home." />
    </Helmet>
    <article className="about">
      <div className="container layout-content">
        <div className="row">
          <div className="col col-desktop9 col-mobile12">
            <h1 className="title-main">We believe there&apos;s a better way to buy a home</h1>
            <div className="subtitle-main row-separationSmall">
              <p>Our mission is to make it easy, simple and affordable to buy the home you want.</p>
            </div>
          </div>
        </div>
        <div className="row about-mission">
          <div className="col col-desktop6 col-tablet12 about-mission-content">
            <h3 className="title-primary">What we do</h3>
            <p>We understand that decisions about home ownership are complicated. Fixed or variable rate, when to remortgage, going direct or using a broker?</p>
            <p>That’s why we’re here. Our goal is to help you navigate these complex decisions by making them easy to understand and simpler to complete.
              We&apos;re starting by helping people remortgage their Help to Buy Equity Loan. But we&apos;ve got big plans ahead..
            </p>
          </div>
          <div className="col col-desktop6 col-tablet12">
            <img src={about} alt="about us" />
          </div>
        </div>
        <div className="about-investors">
          <h3 className="title-primary">Our team</h3>
          <p>Small, but with a big mission. We&apos;re a diverse group of designers, developers and finance professionals who left the traditional world of banking and
            mortgages to transform how people go about buying a home. Backed by some prominent investors as well.
          </p>
          <div className="about-investors-logos">
            <img src={picuscapital} alt="picus capital" />
            <img src={picuscapital} alt="picus capital" />
          </div>
        </div>
      </div>
    </article>
  </Layout>
);

export default About;
