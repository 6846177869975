import React from 'react';
import { Link } from 'react-router-dom';
import helpToBuy from '../images/help-to-buy.svg';

const Claim = () => (
  <article className="claim layout-content">
    <div className="container">
      <div className="row row-gutterSmall">
        <div className="claim-content col col-desktop6 col-mobile12">
          <h1 className="title-main">Get the best from your Help to Buy</h1>
          <div className="subtitle-main row-separationSmall">
            <p>See how much you could save, find out your remortgage options and get expert advice for free.</p>
          </div>
          <Link
            to="/search"
            className="button-success button-big button-claim row-separationBig"
          >
            Get started
          </Link>
          <div className="claim-financial row-separationMedium">
            <img src={helpToBuy} alt="Help to buy" />
          </div>
        </div>
      </div>
    </div>
  </article>
);

export default Claim;
