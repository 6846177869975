import React from 'react';
/* eslint-disable */

const TermsAndConditions = () => (
  <>
    <h1 className="legal-title">Terms and Conditions</h1>
    <ol className="legal-index">
      <li><a href="#introduction">Introduction</a></li>
      <li><a href="#general">General</a></li>
      <li><a href="#our-services">Our Services</a></li>
      <li><a href="#customer-service">Customer Service and Complaints</a></li>
    </ol>
    <ol className="legal-list">
      <li id="introduction">
        <h3 className="title-xxl legal-list-title">Introduction</h3>
        <p>Seven Summits Financial Limited (herein referred to as “Anji“, “we”, “us” or “our”) provides a comparison service for mortgages which enables you to obtain advice or assistance from a third party adviser or lender (the “Service” or “Services”).</p>
        <p>Anji is the trading name of Seven Summits Financial Limited (registered company number 09981525) having its registered office at 13 George St, Marylebone, London, W1U 3QJ.</p>

      </li>
      <li id="general">
        <h3 className="title-xxl legal-list-title">General</h3>
        <p>By accessing and using these Services, you are agreeing to these Terms and Conditions. Please read them carefully.</p>
        <p>We may amend these Terms and Conditions from time to time without notice. You should therefore read them each time you use the Services.</p>
        <p>If you misuse our Services or breach any of these Terms and Conditions, then your right to use the Services will cease immediately.</p>
        <p>You may only accept these terms and use these Services if you are eligible for the respective products in the Service or Services you are accessing.</p>
      </li>
      <li id="our-services">
        <h3 className="title-xxl legal-list-title">Our Services</h3>
        <p>Our comparison Service allows you to research and compare mortgages that may be available to you depending upon your circumstances. The Service is designed to help you make the most of your money, but it is not, and should not be construed as, financial or other professional advice. If you are at all unsure if a product is right for you, you should seek professional advice.</p>
        <p>Our comparison Service is free for you to use. We may receive a fee and/or commission from the third party product provider when you use our Service to purchase products or services from them.</p>
        <p>Additionally, we may offer a Service which enables you to obtain advice or assistance from a third party adviser away from our site (either via a third party website or over the telephone). We do not charge you any fees for this Service. We may receive a fee and/or commission from the third party to whom we refer you.</p>
        <p>Please note that Anji has no control over the third party providers listed in our comparison service. Accordingly, Anji specifically disclaims all liability for any damage or costs of any type arising out of or in any way connected with your use of a provider’s service or product included in our comparison Service. We recommend that you read and understand any terms and conditions relating to a specific product or service before you enter into a contract with your chosen product provider.</p>
        <p>We make every effort to ensure that the data provided by the Service is accurate, however we rely on a wide variety of sources and other websites. We believe these sources of data to be generally accurate and reliable, but sometimes they may not be. Anji makes no claims or representations as to the accuracy, completeness, or truth of any material contained on our site. Nor is Anji liable for any errors or delays in the content or transmission of the data on our site.</p>
        <p>Do not attempt to use our Services through any other interface other than that provided. You may only use our Services as permitted by law and we may withdraw the Services from you if we are investigating suspected misconduct.</p>
        <p>Any use of our Services does not imply the transfer of intellectual property rights or content that may be accessed. You may not use or transfer content from our Services without permission. You may not obstruct, remove or change legal and regulatory notices displayed throughout the Services.</p>
      </li>
      <li id="customer-service">
        <h3 className="title-xxl legal-list-title">Customer Service and Complaints</h3>
        <p>We will aim at all times to provide you with the best service possible. However, occasionally we may not live up to your expectations.</p>
        <p>If you feel our service could have been better, we’d really like to know so we can make sure it doesn't happen again - either to you or to anyone else.</p>
        <p>We are always happy to help and will respond initially within 1 working day. You can contact us at our address: Seven Summits Financial Limited, 13 George Street, Marylebone, London, W1U 3QJ; or email us at hello@anjihomes.com.</p>
        <p>Hopefully, we’ll be able to resolve your complaint to your satisfaction. If, however, anything is unclear or you’re unhappy with our response, please contact the person that handled your complaint. They’ll do all they can to answer your questions and reach an agreement.</p>
      </li>
    </ol>
  </>
);

export default TermsAndConditions;
