import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormContext } from '../MultiStepForm';
import Input from '../../decorators/withInput';
import {
  inputsTypes, inputsDefaultTypes
} from '../../types';

const Step6 = ({ handleChangeInputCurrency, inputs }) => {
  const { setErrorForm , setWarning } = useContext(FormContext);

  useEffect(() => {
    setErrorForm(false);
    setWarning(false);
  }, []);

  useEffect(() => {
    if (inputs.outstandingBalance === undefined) {
      setErrorForm(true);
    } else {
      setErrorForm(false);
    }
  }, [inputs.outstandingBalance]);

  return (
    <article className="steps">
      <h1 className="steps-title">What is the outstanding balance on your mortgage?</h1>
      <p className="steps-subtitle">You should be able to find this on your latest statement</p>
      <div className="steps-form">
        <Input
          name="outstandingBalance"
          format="currency"
          type="tel"
          onChange={handleChangeInputCurrency}
          value={inputs.outstandingBalance}
        />
      </div>
    </article>
  );
};

Step6.propTypes = {
  inputs: inputsTypes,
  handleChangeInputCurrency: PropTypes.func
};


Step6.defaultProps = {
  inputs: inputsDefaultTypes,
  handleChangeInputCurrency: () => {},
};

const mapStateToProps = (state) => ({
  inputs: state.inputs,
});

export default connect(
  mapStateToProps
)(Step6);
