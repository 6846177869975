/* eslint-disable */
export default function PMT (ir, np, pv, fv, type) {
  /*
    * ir   - interest rate per month
    * np   - number of periods (months)
    * pv   - present value
    * fv   - future value
    * type - when the payments are due:
    *        0: end of the period, e.g. end of month (default)
    *        1: beginning of period
  */

  fv || (fv = 0);
  type || (type = 0);

  if (ir === 0) { return -(pv + fv) / np; }

  const pvif = Math.pow(1 + ir, np);
  let pmt = -ir * pv * (pvif + fv) / (pvif - 1);

  if (type === 1) { pmt /= (1 + ir); }

  return pmt;
}
