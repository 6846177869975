import React from 'react';
import { Link } from 'react-router-dom';
import article1 from '../images/article1-min.jpg';
import article2 from '../images/article2-min.jpg';
import article3 from '../images/article3-min.jpg';

const Articles = () => (
  <article className="articles">
    <div className="container">
      <div className="row row-gutterSmall">
        <div className="articles-item col col-desktop4 col-tablet12">
          <img src={article1} alt="TBD" />
          <div className="articles-item-content">
            <p className="articles-item-title"><Link to="/valuation-to-repay-help-to-buy">Why is a valuation needed to repay Help to Buy?</Link></p>
            <div>
              <p>Before you can repay or staircase your Help to Buy Equity Loan, you’ll need a valuation. Learn more about why and how to get one.</p>
            </div>
          </div>
        </div>
        <div className="articles-item col col-desktop4 col-tablet12">
          <img src={article2} alt="TBD" />
          <div className="articles-item-content">
            <p className="articles-item-title"><Link to="/when-is-help-to-buy-ending">When is Help to Buy ending and other changes you need to know</Link></p>
            <div>
              <p>Help to Buy is scheduled to come to an end. Find out when changes come into place and your other options for first time buyers.</p>
            </div>
          </div>
        </div>
        <div className="articles-item col col-desktop4 col-tablet12">
          <img src={article3} alt="TBD" />
          <div className="articles-item-content">
            <p className="articles-item-title"><Link to="/who-are-target-hca">Who are Target HCA?</Link></p>
            <div>
              <p>What is Target HCA and how are they related to your Help to Buy Equity Loan? </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
);

export default Articles;
